import classes from "./YourWallet.module.scss";
import Navigation from "../../components/Navigation/Navigation";
import { connect } from "../../helpers/provider";
import { useDispatch, useSelector } from "react-redux";
import { AppStore } from "../../@redux/reducers/reducers";
import React, {useCallback, useEffect, useState} from "react";
import axios, { AxiosResponse } from "axios";
import cx from 'classnames';
import web3Modal from "../../helpers/web3modal";
import LandPlots from "../../components/Landplots/LandPlots";
import Footer from "../../components/Footer/Footer";
import SideBar from "../../components/SideBar/SideBar";
import { isburgerMenu, sidebarStatus } from "../../@redux/actions";
import { SERVER_URL } from "../..";
import Paginations from "../Gallery/components/Pagination/Pagination";

const LIMIT = 10;

const YourWallet = () => {
  const dispatch = useDispatch()
  const ethAccount = useSelector((state: AppStore) => state.user.userAddress);
  const sitebarStatus = useSelector((state: AppStore) => state.sidebarStatus);
  const totalPages = useSelector((state: AppStore) => state.appliedFilters.totalPages);
  const [myPlots, setMyPlots] = useState<React.ComponentState>([]);
  const [totalPlots, setTotalPlots] = useState<number>(0);
  const [pricing, setPricing] = useState<React.ComponentState>("");
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(isburgerMenu(false));
    dispatch(sidebarStatus(false))
  },[])

  useEffect(() => {
    axios.get(`${SERVER_URL}/pricing`).then((data: AxiosResponse) => setPricing(data.data))
    .catch(error => console.log(error));
    if (web3Modal?.cachedProvider) {
      connect();
    }
  }, []);
  useEffect(() => {
    ethAccount &&
      axios
        .get(`https://app.mars4.me/_tokens?filter[owner]=${ethAccount}`, {params: {total: 1, page: `${currentPage-1},${LIMIT}`}})
        .then((plots) => {
          setMyPlots(plots.data.plots);
          setTotalPlots(plots.data.totalPlots);
        })
        .catch(error => console.log(error));
  }, [ethAccount, currentPage]);

  const onPageChanged = useCallback(
    (event, page) => {
      event.preventDefault();
      if (page !== 0 && page < totalPages + 1) {
        setCurrentPage(page);
      }
    },
    [setCurrentPage, totalPages]
  );

  return (
    <React.Fragment>
      <div className={cx(classes.container, {[classes.lockedContainer]: sitebarStatus})}>
        <Navigation isCart={true} />
        <div className={classes.Header}>
          <div className={classes.HeaderContainer}>
            <div className={classes.HeaderInfo}>
              <h2>Your Wallet:</h2>
              <span>{ethAccount}</span>
            </div>
            <div className={classes.HeaderInfoSize}>
              <h3>
                My Empire Size <span className={classes.span}>{totalPlots}</span>
              </h3>
            </div>
            <div>
          </div>
          </div>
        </div>
        <div className={classes.Container2Landplot}>
          <LandPlots data={myPlots} pageData={"myempire"} />
        </div>
        {totalPlots > 0 && (
          <Paginations
            totalRecords={totalPlots}
            pageLimit={LIMIT}
            pageNeighbours={1}
            onPageChanged={onPageChanged}
            currentPage={currentPage}
          />
        )}
        <div className={myPlots.length === 0 ? classes.footerContainer : classes.footerContainerFull}>
        <Footer />
        </div>
      </div>
      {sitebarStatus ? <SideBar pricing={pricing} /> : null}
    </React.Fragment>
  )
};
export default YourWallet;

