import { provider } from "../../helpers/provider";
import { toBN } from "../../helpers/toBN";
import { ethers } from "ethers";
import BigNumber from "bignumber.js";
import { Erc20Factory } from "../../@types/Erc20Factory";

export class Account {
  public async getAccount(): Promise<string | undefined> {
    const accounts = await provider!.listAccounts();
    return accounts[0];
  }

  public async getChainId(): Promise<number> {
    const network = await provider!.getNetwork();
    
    return network.chainId;
  }

  public async getTokenBalance(tokenAddress: string): Promise<BigNumber> {
    if (tokenAddress === ethers.constants.AddressZero) {
      return toBN(await provider!.getBalance((await this.getAccount())!));
    }
    const tokenInstance = Erc20Factory.connect(tokenAddress, provider!);
    try {
      return toBN(await tokenInstance.balanceOf((await this.getAccount())!));
    } catch (e) {
      return new BigNumber(0);
    }
  }
}
