import axios from "axios";
import { SERVER_URL } from "..";
import { reservedLandplots } from "../@redux/actions";
import store from "../@redux/store";

export const showPlot = (id: string, layer: any, sceneView: any) => {
  if (!layer) {
    return;
  }

  const plotQ = layer.createQuery();
  plotQ.where = `TOKENID = ${id}`;
  layer
    .queryFeatures(plotQ)
    .then((result: { features: string | any[] }) => {
      if (result.features.length) {
        onPlotSelected(result.features[0], sceneView);
      }
    });
};
export const onPlotSelected = (graphic: { center: string }, sceneView: any) => {
          // zoom to plot
  axios
    .get(`${SERVER_URL}/tokens?filter[reserved]=1`)
    .then((plots: any) => {
      store.dispatch(reservedLandplots(plots))
    })
    .catch(error => console.log(error));

  sceneView.goTo({ target: graphic, zoom: 5 }, { duration: 1000 });
  sceneView.popup.open({
    location: graphic.center,
    features: [graphic],
  });
};
export const getQueryVariable = (variable: string) => {
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
};