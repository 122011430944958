import { Route, Switch } from "react-router-dom";
import LandingPage from "../../pages/LandingPage/LandingPage";
import Gallery from "../../pages/Gallery/Gallery";
import SingleLandPlot from "../../pages/SingleLandPlot/SingleLandPlot";
import YourWallet from "../../pages/YourWallet/YourWallet";
import NoMatch from "../../pages/404Page";

export const URL_PREFIX = process.env.REACT_APP_URL_PREFIX || '';

export const routes = {
  landing: `${URL_PREFIX}/`,
  lands: `${URL_PREFIX}/lands`,
  empire: `${URL_PREFIX}/myempire`,
  landPlot: `${URL_PREFIX}/landplot`,
  partnerAffiliate: `${URL_PREFIX}/discount/:promo`
}

const Router = () => {
  return (
    <Switch>
        <Route path={routes.landing} exact component={LandingPage} />
        <Route path={routes.partnerAffiliate} exact component={LandingPage} />
        <Route path={routes.lands} exact component={Gallery} />
        <Route path={routes.empire} exact component={YourWallet}/>
        <Route path={`${routes.landPlot}/:id`} exact component={SingleLandPlot} />
        <Route path="*">
          <NoMatch />
        </Route>
    </Switch>
  );
};

export default Router;
