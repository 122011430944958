import popupStyles from "./CustomPopUp.module.scss";
import ShoppingCart from "../../../../assets/Images/shopping-cart.svg"
import { useDispatch, useSelector } from "react-redux";
import { AppStore } from "../../../../@redux/reducers/reducers";
import { removeItemAddedPopup, sidebarStatus } from "../../../../@redux/actions";

const CustomPopup = () => {
    const dispatch = useDispatch();
    const { addedItems, itemAdded } = useSelector((state: AppStore) => state.cart);
    const sitebarStatus = useSelector((state: AppStore) => state.sidebarStatus);

    const cleanUpFunction = () => {
        dispatch(sidebarStatus(!sitebarStatus));
        dispatch(removeItemAddedPopup(false))
    }
    return (
        <div
            style={{
                visibility: itemAdded ? "visible" : "hidden",
                opacity: itemAdded ? "1" : "0"
            }}
            className={popupStyles.overlay}
        >
            <div className={popupStyles.popup}>
                <div className={popupStyles.bubble}>
                    <label className={popupStyles.dynamicCartNumber}>{addedItems.length}</label>
                </div>
                <img
                    alt="MARS4 CART"
                    src={ShoppingCart}
                    className={popupStyles.shoppingCart}
                />
                <div className={popupStyles.content}>ITEM ADDED TO <label onClick={() => cleanUpFunction()}>YOUR CART.</label></div>
                <span className={popupStyles.close} onClick={() => dispatch(removeItemAddedPopup(false))}>
                    &times;
                </span>
            </div>
        </div>
    );

}
export default CustomPopup;
