import React, { useState, useEffect } from 'react';
import { loadModules } from 'esri-loader';
import axios, { AxiosResponse } from 'axios';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  getQueryVariable,
  onPlotSelected,
  showPlot,
} from '../../hooks/showPlot';
import { AppStore } from '../../@redux/reducers/reducers';
import usePrevious from '../../hooks/usePrevious';
import { SERVER_URL } from '../..';
import store from '../../@redux/store';
import {
  addToCart,
  cartLoadingStatus,
  changeStatus,
  removeDiscount,
  removeFromCart,
  removeItemAddedPopup,
  trackOrderClick,
  reservedLandplots,
} from '../../@redux/actions';
import { Order } from '../../types/order';
import { routes } from '../../config/Router/Router';
import KuCoinLogo from '../../assets/plots/KuCoin.svg';
import LosslessLogo from '../../assets/plots/Lossless.jpg';

const salePlots = [
  29206, 29208, 29210, 29212, 29213, 29214, 29215, 29217, 29218, 29219, 29220,
  30941, 30942, 30943, 30944, 30945, 30946, 30948, 30949, 32130, 32131, 32132,
  32133, 32135, 32136, 32137, 32138, 32140, 32141, 32142, 32728, 32729, 32730,
  32731, 32732, 32733, 32735, 32736, 32737, 32738, 32739, 32740, 32711, 32712,
  32713, 32714, 32715, 32716, 32718, 32719, 32720, 34544, 34546, 34547, 34548,
  34550, 34551, 34552, 34553, 34554, 34555, 34557, 35814, 35815, 35816, 35817,
  35818, 35819, 35821, 35822, 35823, 35825, 35826, 35827, 35828, 35830, 35831,
  35832, 35833, 35834, 35836, 35837, 35838, 37809, 37810, 37812, 37813, 37814,
  37816, 37817, 37818, 37819, 37821, 37822, 37823, 39160, 39161, 39162, 39163,
  39165, 39166, 39167, 39168, 39169, 39170, 39171, 39173, 39174, 39175, 39857,
  39859, 39860, 39861, 39862, 39864, 39865, 39866, 39867, 41273, 41274, 41276,
  41277, 41278, 41279, 41280, 41986, 41987, 41989, 41990, 41991, 41992, 41994,
  41995, 41996, 41997, 41998, 41999, 43427, 43428, 43430, 43431, 43432, 43433,
  43435, 43436, 43437, 43438, 43439, 43441, 43442, 43443,
];

interface Plot {
  id: number;
  description: string;
  discountable?: boolean;
  image: string;
  is_reserved: boolean;
  price?: number;
  price_2?: number;
  price_4?: number;
  price_6?: number;
  options?: { initSlots?: number };
}

interface LayerProps {
  sceneView: React.ComponentState;
  url: string;
  setlayer: React.ComponentState;
}

const MarsLandplots = ({ sceneView, url, setlayer }: LayerProps) => {
  const [graphicsState, setMyGraphics] = useState<React.ComponentState>(null);
  const [featureLayer, setMyFeatureLayer] =
    useState<React.ComponentState>(null);
  const [button, setButton] = useState<any>();
  const cart = useSelector((state: AppStore) => state.cart?.addedItems);
  const order = useSelector((state: AppStore) => state.orders.orderList);
  const ordersToDelete = useSelector(
    (state: AppStore) => state.orders.ordersToDelete
  );
  const reservedPlots = useSelector((state: AppStore) => state.reserved);
  const cartRemoveStatus = useSelector((state: AppStore) => state.cart);
  const orderRemoveStatus = useSelector((state: AppStore) => state.orders);
  const oldCart: React.ComponentState = usePrevious(cart);
  const oldOrder: React.ComponentState = usePrevious(order);
  const [groupes, setGroups] = useState<string[]>([]);
  const [reserved, setReserved] = useState<number[]>([]);
  const [orderGroup, setOrderGroup] = useState<string[]>([]);
  const newOrderArray: Order[] = _.flatten(order);
  const oldOrderArray: Order[] = _.flatten(oldOrder);

  useEffect(() => {
    if (cart.length === 0) {
      store.dispatch(removeDiscount());
    }
  }, [cart]);

  const getPlotPrice = (plot: Plot, slotCount: number): number | undefined => {
    return slotCount === 2
      ? plot.price_2
      : slotCount === 4
      ? plot.price_4
      : plot.price_6 ?? plot.price;
  };

  const hasPrice = (plot: Plot): boolean => {
    return !!plot.price_2 || !!plot.price_4 || !!plot.price_6;
  };

  const getSelectedSlotCount = (div: HTMLDivElement): number => {
    const slot2Button = div.getElementsByClassName('slot-2-button')[0];
    if (!!slot2Button && slot2Button.classList.contains('selected')) {
      return 2;
    }
    const slot4Button = div.getElementsByClassName('slot-4-button')[0];
    if (!!slot4Button && slot4Button.classList.contains('selected')) {
      return 4;
    }
    return 6;
  };

  const updateSelectionsStatus = (
    div: HTMLDivElement,
    slots: number,
    inCart: boolean
  ) => {
    const slot2Button = div.getElementsByClassName('slot-2-button')[0];
    const slot4Button = div.getElementsByClassName('slot-4-button')[0];
    const slot6Button = div.getElementsByClassName('slot-6-button')[0];

    slot2Button?.classList?.remove('selected');
    slot4Button?.classList?.remove('selected');
    slot6Button?.classList?.remove('selected');
    if (inCart) {
      slot2Button?.classList?.add('disabled');
      slot4Button?.classList?.add('disabled');
      slot6Button?.classList?.add('disabled');
    }

    if (slots === 2) {
      slot2Button?.classList?.add('selected');
      slot2Button?.classList?.remove('disabled');
    } else if (slots === 4) {
      slot4Button?.classList?.add('selected');
      slot4Button?.classList?.remove('disabled');
    } else {
      slot6Button?.classList?.add('selected');
      slot6Button?.classList?.remove('disabled');
    }
  };

  const isPriceVisible = (
    feature: { graphic: { attributes: { OWNER: string } } },
    plot: Plot
  ) => {
    const priceExist = hasPrice(plot);
    return (
      feature.graphic.attributes.OWNER === '0x0' &&
      !plot.is_reserved &&
      priceExist
    );
  };

  const getPlotImage = (data: any) => {
    if (data.id === 854) {
      return KuCoinLogo;
    }

    if (data.id === 15533) {
      return LosslessLogo;
    }

    return data.image;
  };

  const getPlotImageClass = (data: any) => {
    if (data.id === 854) {
      return 'KuCoinPlotImage';
    }

    if (data.id === 15533) {
      return 'LosslessPlotImage';
    }

    return 'LandPlotImage';
  };

  const getDescription = (data: any) => {
    if (data.id === 854) {
      return 'Landplot #854 owned by KuCoin exchange';
    }

    if (data.id === 15533) {
      return 'Lossless - your ally against hacks in web3';
    }

    return '';
  };

  const getButtonText = (data: any) => {
    if (data.id === 854) {
      return 'Visit KuCoin office';
    }

    if (data.id === 15533) {
      return 'Visit Lossless space';
    }

    return 'MORE INFO';
  };

  const getButtonLink = (data: any) => {
    if (data.id === 854) {
      return 'https://app.mars4.me/office/KuCoin/';
    }

    if (data.id === 15533) {
      return 'https://app.mars4.me/office/Lossless/';
    }

    return `https://app.mars4.me${routes.landPlot}/${data.id}`;
  };

  const getButtonTarget = (data: any) => {
    if (data.id === 854) {
      return '_blank';
    }

    if (data.id === 15533) {
      return '_blank';
    }

    return '_self';
  };

  useEffect(() => {
    if (sceneView) {
      loadModules([
        'esri/layers/FeatureLayer',
        'esri/layers/GraphicsLayer',
        'esri/Graphic',
        'esri/symbols/SimpleFillSymbol',
      ])
        .then(([FeatureLayer, GraphicsLayer]) => {
          const layer = new FeatureLayer({
            url: url,
            outFields: ['*'],
            renderer: {
              type: 'unique-value',
              field: 'OWNER',
              defaultSymbol: {
                type: 'simple-fill', // autocasts as new SimpleFillSymbol()
                color: [255, 0, 0, 0.3],
              },
              uniqueValueInfos: [
                {
                  value: '0x0',
                  symbol: {
                    type: 'simple-fill', // autocasts as new SimpleFillSymbol()
                    color: [255, 255, 255, 0.1],
                  },
                },
              ],
            },
            labelingInfo: [
              {
                labelPlacement: 'above-center',
                labelExpressionInfo: {
                  expression: 'IIf($feature.TOKENID <= 888, $feature.NAME, "")',
                },
                symbol: {
                  type: 'label-3d',
                  symbolLayers: [
                    {
                      type: 'text',
                      material: {
                        color: [0, 0, 0, 0.9],
                      },
                      halo: {
                        size: 2,
                        color: [255, 255, 255, 0.7],
                      },
                      font: {
                        size: 10,
                      },
                    },
                  ],
                  verticalOffset: {
                    screenLength: 40,
                    maxWorldLength: 500000,
                    minWorldLength: 0,
                  },
                  callout: {
                    type: 'line',
                    size: 0.5,
                    color: [255, 255, 255, 0.9],
                    border: {
                      color: [0, 0, 0, 0.3],
                    },
                  },
                },
              },
            ],
            popupTemplate: {
              content: async (feature: {
                graphic: {
                  attributes: {
                    TOKENID: number;
                    OWNER: string;
                    OBJECTID: string;
                    NAME: string;
                  };
                };
              }) => {
                const State = store.getState();
                const plotResponse: AxiosResponse<Plot> = await axios.get(
                  `${SERVER_URL}/tokens/${feature.graphic.attributes.TOKENID}`
                );
                const plot = plotResponse.data;
                const cartItem = State.cart.addedItems.find(
                  (c: { TOKENID: number; SLOT_COUNT: number }) =>
                    c.TOKENID === feature.graphic.attributes.TOKENID
                );
                const slots = cartItem?.SLOT_COUNT ?? 6;
                const slotOptionsCount = [
                  plot.price_2,
                  plot.price_4,
                  plot.price_6,
                ].filter((item) => item !== undefined).length;
                const price = getPlotPrice(plot, slots);
                let div = document.createElement('div');
                let div2 = document.createElement('div');
                div.className = 'wrapper d-flex';
                div2.innerHTML = `
              ${
                feature.graphic.attributes.OWNER === '0x0' &&
                hasPrice(plot) &&
                plot.id !== 15533
                  ? `
              <button data-plot-id="${plot.id}" id="button" class="btn-cart ${
                      State.orders.orderList.some((c: Order[]) =>
                        c.find(
                          (item) =>
                            item.TOKENID === feature.graphic.attributes.TOKENID
                        )
                      ) || State.reserved.find((c: number) => c === plot.id)
                        ? 'd-none'
                        : 'addCartButton'
                    }">${
                      State.cart.addedItems.find(
                        (c: { TOKENID: number }) =>
                          c.TOKENID === feature.graphic.attributes.TOKENID
                      )
                        ? 'REMOVE'
                        : 'ADD TO CART'
                    }</button>`
                  : ''
              }    
              `;
                div.innerHTML = `
              <div class="row-auto d-flex">
              <div className="">
            <img class="${getPlotImageClass(plot)}" src="${getPlotImage(
                  plot
                )}" class="" />
              </div>
              <div class="popUpLayoutContainer bg-light w-100">
              <p id="ObjectID" class="">${
                price === 100 ? 'UN co-funded land ' : ''
              }${feature.graphic.attributes.NAME}</p>
              <p class="popUpLayout">No. ${plot.id}</p>
              <p class="popUpLayout">${getDescription(plot)}</p>

              ${
                isPriceVisible(feature, plot)
                  ? slotOptionsCount > 1
                    ? `
              <div class="popUpSlotSelectionContainer">
              ${
                !!plot.price_6
                  ? `
                <button class="popUpSlotSelectionItem slot-6-button">
                  <div class="poUpSlotImg"></div>
                  <span>6</span>
                </button>
                `
                  : ''
              }
              </div>
              <div class="popUpPricingContainer">
                <p class="popUpPriceTitle">Price:</p>
                <p class="popUpPriceValue">${price} USD</p>
              </div>
              `
                    : `<p class="plotPricing">Price: ${price} USD</>`
                  : ''
              }
              <p class="address">${
                feature.graphic.attributes.OWNER === '0x0'
                  ? `<p class="availableText">${
                      plot.is_reserved ||
                      State.reserved.find((c: number) => c === plot.id) ||
                      !hasPrice(plot) ||
                      plot.id === 15533 ||
                      State.orders.orderList.some((c: Order[]) =>
                        c.find((item) => item.TOKENID === plot.id)
                      )
                        ? ''
                        : plot.price === 100
                        ? 'Claim and save 82%'
                        : 'AVAILABLE'
                    }</p>${
                      salePlots.includes(plot.id)
                        ? '<a href="https://control.mars4.me/campaigns" target="_blank" style="color: blue; text-decoration: underline; font-weight: bold;">Bonus cargo located!</a>'
                        : ''
                    }`
                  : plot.id === 15533
                  ? ''
                  : '<p class="footerParagraph">OWNER</p><a class="ownerAddress" href="' +
                    window.location.protocol +
                    '//' +
                    window.location.host +
                    window.location.pathname +
                    '?owner=' +
                    feature.graphic.attributes.OWNER +
                    '#' +
                    feature.graphic.attributes.TOKENID +
                    '">' +
                    feature.graphic.attributes.OWNER +
                    '</a></p>'
              }
                       ${div2.innerHTML}

                       ${
                         feature.graphic.attributes.OWNER === '0x0' &&
                         !hasPrice(plot) &&
                         plot.id < 888
                           ? `${
                               plot.id !== 854
                                 ? `<p class="availableText" >Wait for auction</p>`
                                 : ''
                             } <button class="moreInfoBtn"><a href="${getButtonLink(
                               plot
                             )}" target="${getButtonTarget(
                               plot
                             )}"> ${getButtonText(plot)}</a></button>`
                           : ''
                       }

                        ${
                          plot.id === 15533
                            ? `<button class="moreInfoBtn"><a href="${getButtonLink(
                                plot
                              )}" target="${getButtonTarget(
                                plot
                              )}"> ${getButtonText(plot)}</a></button>`
                            : ''
                        }
                        
                        ${
                          plot.id === 18
                            ? '<div> <p class="NewtonDubaiP">Watch VR Trailer:</p> <a href="https://www.mars4.me/vr-experience" class="availableTextDubai" >DUBAI 2040 | MARS EDITION</a></div>'
                            : ''
                        }
                
                        ${
                          plot.is_reserved ||
                          State.reserved.find((c: number) => c === plot.id) ||
                          State.orders.orderList.some((c: Order[]) =>
                            c.find((item) => item.TOKENID === plot.id)
                          )
                            ? '<button type="button" id="buttonDisabled" class="btn-cart" disabled >IS RESERVED</button>'
                            : ''
                        }
                        ${
                          State.reserved.find((c: number) => c === plot.id) &&
                          State.cart.addedItems.some(
                            (item: any) =>
                              item.TOKENID ===
                              feature.graphic.attributes.TOKENID
                          )
                            ? '<button type="button"  class="btn-cart addCartButton" id="buttonRe">REMOVE FROM CART</button>'
                            : ''
                        }
                        </div>
                        </div>
                        <div class="footer">
                      
                </div>`;
                div.className = 'footeris';

                updateSelectionsStatus(div, slots, !!cartItem);

                const slot2Button =
                  div.getElementsByClassName('slot-2-button')[0];
                const slot4Button =
                  div.getElementsByClassName('slot-4-button')[0];
                const slot6Button =
                  div.getElementsByClassName('slot-6-button')[0];
                const priceValue =
                  div.getElementsByClassName('popUpPriceValue')[0];

                slot2Button?.addEventListener('click', function (e) {
                  updateSelectionsStatus(div, 2, !!cartItem);
                  if (!!priceValue) {
                    const price = getPlotPrice(plot, 2);
                    priceValue.innerHTML = `${price} USD`;
                  }
                });

                slot4Button?.addEventListener('click', function (e) {
                  updateSelectionsStatus(div, 4, !!cartItem);
                  if (!!priceValue) {
                    const price = getPlotPrice(plot, 4);
                    priceValue.innerHTML = `${price} USD`;
                  }
                });

                slot6Button?.addEventListener('click', function (e) {
                  updateSelectionsStatus(div, 6, !!cartItem);
                  if (!!priceValue) {
                    const price = getPlotPrice(plot, 6);
                    priceValue.innerHTML = `${price} USD`;
                  }
                });

                const selectedButton =
                  div.getElementsByClassName('addCartButton');
                if (selectedButton.length !== 0) {
                  selectedButton[0].addEventListener('click', function (e) {
                    const slots = getSelectedSlotCount(div);
                    const price = getPlotPrice(plot, slots);
                    updateSelectionsStatus(div, slots, true);
                    addToCartFunction(feature, slots, price, plot.discountable);
                  });
                }

                return div;
              },
            },
          });
          setMyFeatureLayer(layer);
          setlayer(layer);
          sceneView.on('click', function (event: HTMLElement) {
            setButton(document.getElementById('button'));
            sceneView
              .hitTest(event, { include: layer })
              .then((response: { results: any }) => {
                if (response.results.length) {
                  onPlotSelected(response.results[0].graphic, sceneView);
                }
              });
          });

          if (window.location.hash) {
            showPlot(window.location.hash.replace('#', ''), layer, sceneView);
          }
          const addToCartFunction = (
            feature: any,
            slotCount: number,
            priceUSD?: number,
            discountable?: boolean
          ) => {
            axios
              .get(`${SERVER_URL}/tokens?filter[reserved]=1`)
              .then((plots: any) => {
                store.dispatch(reservedLandplots(plots));
                if (
                  plots.data.find(
                    (item: any) => item === feature.graphic.attributes.TOKENID
                  )
                ) {
                  const button = document.getElementById(
                    'button'
                  ) as HTMLButtonElement;
                  const buttonRe = document.getElementById(
                    'buttonRe'
                  ) as HTMLButtonElement;
                  const paragraph =
                    document.getElementsByClassName('availableText');
                  paragraph[0].innerHTML = '';
                  if (buttonRe !== null) {
                    buttonRe.style.display = 'none';
                  }
                  button.innerHTML = 'IS RESERVED';
                  button.disabled = true;
                  button.style.backgroundColor = 'grey';

                  store.dispatch(cartLoadingStatus(true));
                  store.dispatch(
                    removeFromCart(feature.graphic.attributes.TOKENID)
                  );
                  return null;
                } else {
                  store.dispatch(cartLoadingStatus(true));
                  const button = document.getElementById(
                    'button'
                  ) as HTMLButtonElement;
                  if (
                    button.innerHTML === 'REMOVE' ||
                    cart.some(
                      (c: { TOKENID: number }) =>
                        c.TOKENID === feature.graphic.attributes.TOKENID
                    )
                  ) {
                    setButton(button);
                    store.dispatch(removeItemAddedPopup(false));
                    store.dispatch(
                      removeFromCart(feature.graphic.attributes.TOKENID)
                    );

                    button.innerHTML = 'ADD TO CART';
                  } else {
                    // @ts-ignore
                    window.fbq('track', 'AddToCart');
                    // @ts-ignore
                    window.esk('event', 'AddToCart');
                    store.dispatch(
                      addToCart({
                        ...feature.graphic.attributes,
                        PRICE_USD: priceUSD,
                        SLOT_COUNT: slotCount,
                        DISCOUNTABLE: discountable ?? true,
                      })
                    );
                    if (button) {
                      button.innerHTML = 'REMOVE';
                    }
                  }
                }
              })
              .catch((error) => console.log(error));
          };

          const graphics = new GraphicsLayer();
          setMyGraphics(graphics);
          if (sceneView.map) {
            if (sceneView.map.layers.length < 4) {
              sceneView.map.add(layer);
            }
          }
        })
        .catch((err: Error) => console.error(err));
    }
    return function cleanup() {
      if (sceneView !== null) {
        sceneView.map.layers.remove(featureLayer);
      }
    };
  }, [sceneView]);

  useEffect(() => {
    if (cartRemoveStatus.removeAll) {
      setGroups([]);
    }
  }, [cartRemoveStatus.removeAll]);

  useEffect(() => {
    loadModules(['esri/Graphic', 'esri/symbols/SimpleFillSymbol']).then(
      ([Graphic, SimpleFillSymbol]) => {
        if (graphicsState) {
          const groups: any = {};
          const markPlots = (group: string, ids: number[], color: number[]) => {
            const q = featureLayer.createQuery();
            q.where = `TOKENID IN (${ids.join(',')})`;
            const button = document.getElementById(
              'button'
            ) as HTMLButtonElement;
            if (ids.length !== 0) {
              featureLayer
                .queryFeatures(q)
                .then((result: { features: any[] }) => {
                  groups[group] = [];
                  if (cartRemoveStatus.removeAll && button === null) {
                    result.features.forEach((f: any) => {
                      let selectedObject = groupes.find((index: any) => {
                        return index.attributes.id === f.attributes.TOKENID;
                      });
                      graphicsState.remove(selectedObject);
                      setGroups([]);
                    });
                  } else if (button === null && !cartRemoveStatus.removeAll) {
                    result.features.forEach((f) => {
                      const g = new Graphic({
                        geometry: f.geometry.clone(),
                        symbol: new SimpleFillSymbol({
                          color: color,
                        }),
                        attributes: {
                          id: f.attributes.TOKENID,
                        },
                      });

                      groups[group].push(g);
                      setGroups([...groups[group]]);
                    });
                  } else {
                    const btnAttr: any = button.getAttribute('data-plot-id');
                    const tokenId = parseInt(btnAttr);
                    const newObject = result.features.find((index: any) => {
                      return index.attributes.TOKENID === tokenId;
                    });
                    if (
                      button.innerHTML === 'REMOVE' &&
                      !cartRemoveStatus.removeAll
                    ) {
                      const g = new Graphic({
                        geometry: newObject.geometry.clone(),
                        symbol: new SimpleFillSymbol({
                          color,
                        }),
                        attributes: {
                          id: newObject.attributes.TOKENID,
                        },
                      });
                      groups[group].push(g);
                      if (cartRemoveStatus.removeAll) {
                        setGroups([]);
                      } else {
                        setGroups([...groupes, g]);
                      }
                    } else {
                      const q = featureLayer.createQuery();
                      q.where = `TOKENID IN (${ids.join(',')})`;
                      featureLayer.queryFeatures(q).then((result: any) => {
                        const newObject = result.features.find((index: any) => {
                          return index.attributes.TOKENID === tokenId;
                        });
                        graphicsState.removeMany(result.features[0] || []);
                        groups[group] = [];
                        if (newObject && !cartRemoveStatus.removeAll) {
                          const g = new Graphic({
                            geometry: newObject.geometry.clone(),
                          });
                          groups[group].push(g);
                          let selectedObject = groupes.find((index: any) => {
                            return index.attributes.id === tokenId;
                          });
                          graphicsState.remove(selectedObject);
                          setGroups(
                            groupes.filter(
                              (item: any) => item.attributes.id !== tokenId
                            )
                          );
                        } else {
                          result.features.forEach((f: any) => {
                            const g = new Graphic({
                              geometry: f.geometry.clone(),
                              attributes: {
                                id: f.attributes.TOKENID,
                              },
                            });
                            groups[group].push(g);
                            let selectedObject = groupes.find((index: any) => {
                              return (
                                index.attributes.id === f.attributes.TOKENID
                              );
                            });
                            graphicsState.remove(selectedObject);
                            setGroups([]);
                          });
                        }
                      });
                    }
                    store.dispatch(trackOrderClick(false));
                  }
                  graphicsState.addMany(groups[group]);
                });
            }
          };
          const markOrderPlots = (
            group: string,
            ids: number[],
            color: number[]
          ) => {
            if (!orderRemoveStatus.removeAll) {
              const q = featureLayer.createQuery();
              q.where = `TOKENID IN (${ids.join(',')})`;
              if (ids.length !== 0) {
                featureLayer.queryFeatures(q).then((result: any) => {
                  groups[group] = [];
                  if (orderGroup.length === 0) {
                    result.features.forEach((f: any) => {
                      const g = new Graphic({
                        geometry: f.geometry.clone(),
                        symbol: new SimpleFillSymbol({
                          color,
                        }),
                        attributes: {
                          id: f.attributes.TOKENID,
                        },
                      });
                      groups[group].push(g);
                      setOrderGroup([...groups[group]]);
                    });
                  } else {
                    const matched: any[] = [];
                    for (let i of result.features) {
                      if (
                        !orderGroup.find(
                          (val: any) =>
                            val.attributes.id === i.attributes.TOKENID
                        )
                      )
                        matched.push(i);
                    }
                    matched.map((f: any) => {
                      const g = new Graphic({
                        geometry: f.geometry.clone(),
                        symbol: new SimpleFillSymbol({
                          color,
                        }),
                        attributes: {
                          id: f.attributes.TOKENID,
                        },
                      });
                      groups[group].push(g);
                      setOrderGroup((oldOrderGroup) => [...oldOrderGroup, g]);
                    });
                  }
                  graphicsState.addMany(groups[group]);
                });
              }
            } else {
              const newArray: any = orderGroup.filter(function (x: any) {
                return ordersToDelete!.includes(x.attributes.id);
              });

              newArray.forEach((key: any) => {
                graphicsState.remove(key);
                setOrderGroup((prevOrderGroup) =>
                  prevOrderGroup.filter(
                    (orderLayer: any) =>
                      orderLayer.attributes.id !== key.attributes.id
                  )
                );
              });
              store.dispatch(trackOrderClick(false));
            }
          };
          const markReservedPlots = (
            group: string,
            ids: number[],
            color: number[]
          ) => {
            const q = featureLayer.createQuery();
            setReserved(ids);
            q.where = `TOKENID IN (${ids.join(',')})`;
            if (ids.length !== reserved.length) {
              featureLayer.queryFeatures(q).then((result: any) => {
                graphicsState.removeMany(groups[group] || []);
                groups[group] = [];
                const matched: any[] = [];
                for (let i of result.features) {
                  if (
                    !reserved.find((val: any) => val === i.attributes.TOKENID)
                  )
                    matched.push(i);
                }
                matched.map((f: any) => {
                  const g = new Graphic({
                    geometry: f.geometry.clone(),
                    symbol: new SimpleFillSymbol({
                      color,
                    }),
                    attributes: {
                      id: f.attributes.TOKENID,
                    },
                  });
                  groups[group].push(g);
                });
                graphicsState.addMany(groups[group]);
              });
              store.dispatch(changeStatus('LOADED'));
            }
          };
          sceneView.map.add(graphicsState);

          const owner = getQueryVariable('owner');
          if (owner) {
            axios
              .get(`${SERVER_URL}/tokens?filter[owner]=${owner}`)
              .then((plots: AxiosResponse) =>
                markReservedPlots(
                  'owner',
                  plots.data.map((p: { id: string }) => p.id),
                  [0, 255, 0, 0.5]
                )
              );
          }

          if (cartRemoveStatus.loading) {
            if (cart.length > oldCart.length) {
              markPlots(
                'cart',
                cart.map((p: { TOKENID: number }) => p.TOKENID),
                [0, 255, 0, 0.1]
              );
            } else {
              markPlots(
                'oldCart',
                oldCart.map((p: { TOKENID: number }) => p.TOKENID),
                [0, 255, 0, 0.1]
              );
            }
          }

          if (orderRemoveStatus.loading) {
            if (order.length) {
              markOrderPlots(
                'order',
                newOrderArray.map((p) => p.TOKENID),
                [255, 255, 0, 0.4]
              );
            } else {
              markOrderPlots(
                'order',
                oldOrderArray.map((p) => p.TOKENID),
                [255, 255, 0, 0.4]
              );
            }
          }
          markReservedPlots(
            'reservedPlots',
            reservedPlots.map((p: number) => p),
            [255, 255, 0, 0.2]
          );

          markPlots('sale', salePlots, [8, 79, 150, 0.5]);
        }
      }
    );
    return function cleanup() {
      if (sceneView !== null) {
        sceneView.map.layers.remove(graphicsState);
      }
    };
  }, [graphicsState, order, cart, reservedPlots]);

  return null;
};
export default MarsLandplots;
