//Style
import classes from "./DropDown.module.scss";
//img
import { useDispatch, useSelector } from "react-redux";
import { filterLandplotByInputType } from "../../../../@redux/actions/filterAction";
import React, { useEffect, useState } from "react";
import { AppStore } from "../../../../@redux/reducers/reducers";

const Dropdown = ({ currentPage,onPageChanged }: React.ComponentState) => {
  const dispatch = useDispatch();
  const { input, getSortSelectID, selectSortValue, totalPages } = useSelector((state: AppStore) => state.appliedFilters);

  const [inputValue, setInput] = useState<any>(input);
  const [selectedSortValue, setSelectSortValue] = useState<string>(selectSortValue)
  const [selectedSortId, setSelectedSortId] = useState<number>(getSortSelectID)
  const filterByName = async (e: any) => {
    let input = e.target.value;
    setInput(input);
    onPageChanged(e,1)
  }
  useEffect(() => {
    const getSortSelectID: any = document.getElementById('sortValue');
    getSortSelectID.selectedIndex = selectedSortId;
    let isNumber = !isNaN(inputValue);
    if (!isNumber) {
      dispatch(filterLandplotByInputType(inputValue, currentPage, "name", selectedSortValue, selectedSortId))
    } else {
      dispatch(filterLandplotByInputType(inputValue, currentPage, "id", selectedSortValue, selectedSortId))
    }
  }, [inputValue, selectedSortValue, currentPage, totalPages])

  const clearFilterInputs = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const getSortSelectID: any = (document.getElementById('sortValue') as any).selectedIndex === 0;
    setSelectedSortId(getSortSelectID)
    setInput("")
    setSelectSortValue("")
    onPageChanged(e,1)
  }

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const getSortSelectID: any = (document.getElementById('sortValue') as any).selectedIndex;
    setSelectSortValue(e.target.value)
    setInput("")
    onPageChanged(e,1)
    setSelectedSortId(getSortSelectID)
  }

  return (
    <>
      <div className={classes.Container}>
        <h1>DISCOVER MARS4 LANDS</h1>
        <h4>WHAT ARE YOU LOOKING FOR?</h4>
        <div className={classes.DropdownContainer}>
          <div className={classes.Dropdown}>
            <form action="" className={classes.Input}>
              <select id="sortValue" onChange={handleChange} className={classes.StatusContainer}>
                <option defaultChecked value="">CHOOSE RARITY</option>
                <option value="epic">Epic</option>
                <option value="super-rare">Super Rare</option>
                <option value="rare">Rare</option>
                <option value="unique">Unique</option>
                <option value="common">Common</option>
              </select>
            </form>
          </div>
          <input className={classes.filterContainer} onChange={(e: any) => filterByName(e)} type="text" value={inputValue} placeholder="TYPE NAME OR THE ID NUMBER" />
          <div onClick={(e:any) => clearFilterInputs(e)} className={classes.clearFilter}>Clear filters</div>

        </div>
      </div>
    </>
  );
};
export default Dropdown;