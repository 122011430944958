import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
// import {theme} from "../styles/theme";
// import { isServer } from "./isServer";

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: "a14b3e348ffe478d8263543a4848deee", // required
    },
  },
};

const web3Modal = new Web3Modal({
  network:"mainnet",
  cacheProvider: true, // optional
  providerOptions, // required
  // theme: {
  // background: theme.palette.background.paper,
  // main: theme.palette.text.primary,
  // secondary: theme.palette.text.secondary,
  // border: theme.palette.divider,
  // hover: theme.palette.action.hover,
  // },
});

export default web3Modal;
