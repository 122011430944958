import React, { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../@redux/reducers/reducers';
import store from '../../@redux/store';
import classes from './SideBar.module.scss';
import Cart from '../../assets/Images/cart-grey.svg';
import {
  addToOrder,
  cartLoadingStatus,
  removeAllCartItems,
  removeDiscount,
  removeItemAddedPopup,
  reservedLandplots,
  setDiscountForUser,
  sidebarStatus,
} from '../../@redux/actions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showPlot } from '../../hooks/showPlot';
import { SERVER_URL } from '../../index';
import useWindowSize from '../../utils/windowSize';
import OrderContainer from './components/OrderContainer';
import { useLocation } from 'react-router';
import queryString from 'query-string';

const SideBar = ({
  pricing,
  layer,
  sceneView,
  promo,
}: React.ComponentState) => {
  const dispatch = useDispatch();
  const cart = useSelector((state: AppStore) => state.cart?.addedItems);
  const { enabled } = useSelector((state: AppStore) => state.cart?.discount);
  const appliedDiscount = useSelector(
    (state: AppStore) => state.cart?.discount.discount
  );
  const appliedDiscountCode = useSelector(
    (state: AppStore) => state.cart?.discount.code
  );
  const order = useSelector((state: AppStore) => state.orders.orderList);
  const sitebarStatus = useSelector((state: AppStore) => state.sidebarStatus);
  const reserevedPlots = useSelector((state: AppStore) => state.reserved);
  const [discount, setDiscount] = useState<string>('');
  const { search } = useLocation();

  const parsedSearch = queryString.parse(search);
  const parsedClickIdSource = parsedSearch['clickid'];

  const pricingValue = (slots: number) =>
    Number(
      slots === 2
        ? pricing.plot_price_2.eth
        : slots === 4
        ? pricing.plot_price_4.eth
        : pricing.plot_price_6.eth
    );
  const landPlotsPriceinETH = cart
    .map((item) =>
      item.PRICE_USD
        ? item.PRICE_USD * Number(pricing.eth_usd_rate)
        : pricingValue(item.SLOT_COUNT)
    )
    .reduce((accumulator, current) => {
      return accumulator + current;
    }, 0);
  const setDiscountEth = cart
    .map((item) => {
      const priceEth = item.PRICE_USD
        ? item.PRICE_USD * Number(pricing.eth_usd_rate)
        : pricingValue(item.SLOT_COUNT);
      if (appliedDiscount && item.DISCOUNTABLE) {
        return priceEth * (appliedDiscount / 100);
      }
      return 0;
    })
    .reduce((accumulator, current) => {
      return accumulator + current;
    }, 0);
  const setDiscountForETH = landPlotsPriceinETH - setDiscountEth;
  const haveNotDiscountablePlot = cart.find(
    (item) => (item.DISCOUNTABLE ?? true) === false
  );

  const handleRemove = async () => {
    setDiscount('');
    dispatch(removeDiscount());
    toast.success('Coupon code removed.', { autoClose: 3000 });
  };

  const handleDiscount = async (promoCode: string) => {
    if (promoCode === '') {
      toast.error('Wrong coupon code.', { autoClose: 3000 });
    } else {
      try {
        await axios
          .get(`https://app.mars4.me/coupons/${promoCode}`)
          .then(function (res) {
            const newDate = new Date().getTime();
            const expireDate = new Date(res.data.expires_at).getTime();
            if (
              (+expireDate > +newDate && res.data.enabled) ||
              (expireDate === 0 && res.data.enabled)
            ) {
              toast.success('Discount code applied!');
              dispatch(setDiscountForUser(res.data));
            } else {
              setDiscount('');
              toast.error(
                `${
                  +expireDate < +newDate && expireDate !== 0
                    ? 'Coupon code is expired'
                    : 'Coupon code is not valid.'
                }`,
                { autoClose: 3000 }
              );
            }
          });
      } catch (err) {
        toast.error('Wrong Coupon code.', { autoClose: 3000 });
      }
    }
  };

  const { width } = useWindowSize();
  if (width > 980) {
    dispatch(sidebarStatus(true));
  }
  const addToRecentOrdersList = async () => {
    // @ts-ignore
    window.fbq('track', 'InitiateCheckout');
    axios
      .get(`${SERVER_URL}/tokens?filter[reserved]=1`)
      .then(async (plots: any) => {
        store.dispatch(reservedLandplots(plots));
        if (
          plots.data.find((item: any) =>
            cart.some((cartItem: any) => item === cartItem.TOKENID)
          )
        ) {
          const overlayedLands = plots.data.find((item: any) =>
            cart.some((cartItem: any) => item === cartItem.TOKENID)
          );
          toast.error(`Landplot #${overlayedLands} is already reserved`, {
            autoClose: 3000,
          });
        } else {
          store.dispatch(cartLoadingStatus(true));
          setDiscount('');
          const button = document.querySelectorAll('.btn-cart');
          const paragraph = document.getElementsByClassName('availableText');

          if (paragraph.length > 0) {
            paragraph[0].innerHTML = '';
          }

          button.forEach((element) => {
            element.classList.value = 'd-none';
          });
          const plotIds = cart.map((item: { TOKENID: number }) => {
            return item.TOKENID;
          });
          const plotSlots = cart.map((item: { SLOT_COUNT: number }) => {
            return item.SLOT_COUNT;
          });
          dispatch(removeItemAddedPopup(false));
          axios
            .get(`${SERVER_URL}/tokens?filter[reserved]=1`)
            .then((plots: AxiosResponse) => {
              if (plots.data.length !== reserevedPlots.length)
                dispatch(reservedLandplots(plots));
            })
            .catch((error) => console.log(error));
          const couponCode = appliedDiscountCode;
          await axios
            .post(`${SERVER_URL}/orders`, {
              couponCode,
              plotIds,
              plotSlots,
              paymentAmount: landPlotsPriceinETH,
              clickId: parsedClickIdSource,
            })
            .then(function (res) {
              store.dispatch(addToOrder(cart, res.data));
            });
        }
      })
      .catch((error) => console.log(error));
  };

  const singleBlockPop = (e: EventTarget & HTMLSpanElement) => {
    const attr = e.getAttribute('data-plot-id')!;
    dispatch(sidebarStatus(!sitebarStatus));
    showPlot(attr, layer, sceneView);
  };

  useEffect(() => {
    if (cart.length === 0) {
      setDiscount('');
    }
  }, [cart]);

  useEffect(() => {
    if (promo) {
      handleDiscount(promo);
    }
  }, [promo]);

  const handleClearAll = () => {
    store.dispatch(removeAllCartItems());

    const button = document.getElementById('button') as HTMLButtonElement;
    button.innerHTML = 'ADD TO CART';
  };

  return (
    <>
      <div
        id='sidebar'
        className={`Modal ${sitebarStatus || width > 980 ? 'Show' : ''}`}
      >
        <div id='sidebar' className={classes.wrapper}>
          <div className={classes.SideBarInsideContainer}>
            <div className={classes.SideBarCartContent}>
              <div className={classes.SideBarCartWrapper}>
                <h4 className={classes.H4fontStyle}>CART</h4>
                <h4 className={classes.SideBarCartSpecificColor}>
                  {cart.length}
                </h4>
                {cart.length > 0 && (
                  <button
                    className={classes.SideBarClearCartButton}
                    onClick={handleClearAll}
                  >
                    CLEAR CART
                  </button>
                )}
              </div>

              <div>
                {width < 601 ? (
                  <p onClick={() => dispatch(sidebarStatus(!sitebarStatus))}>
                    X
                  </p>
                ) : null}
              </div>
            </div>
            <div className={classes.SideBarCustomDashedLine} />
            {cart.length === 0 ? (
              <>
                <p className={classes.SideBarCartNoItems}>
                  <img src={Cart} alt='Mars4 purchase cart for landplots' />
                  YOUR CART IS EMPTY.
                </p>
                {width < 980 ? (
                  <p className={classes.customText}>
                    View map and select a landplot.
                  </p>
                ) : (
                  <p className={classes.customText}>
                    Select landplot on the map.
                  </p>
                )}
              </>
            ) : (
              <>
                <div className={classes.SideBarCartOrderBlocks}>
                  <div className={classes.SideBarLandPlotsWrapper}>
                    <h4 className={classes.H4fontStyleCourier}>Landplots</h4>
                    <div className={classes.cartLandPlots}>
                      {cart.map((feature: any, index: number) => {
                        const lastElement = cart.slice(-1);
                        const slotCount =
                          !!feature.SLOT_COUNT && feature.SLOT_COUNT < 6 ? (
                            <span>{`(${feature.SLOT_COUNT})`}</span>
                          ) : undefined;
                        return (
                          <span
                            key={index}
                            data-plot-id={feature.TOKENID}
                            onClick={(e) => singleBlockPop(e.currentTarget)}
                            className={classes.SideBarRecentOrderBox}
                          >
                            {`#${feature.TOKENID}`}
                            {slotCount}
                            {lastElement[0]?.TOKENID === feature.TOKENID
                              ? ''
                              : ','}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className={classes.SideBarCustomDashedLine} />
                <div className={classes.discountBlock}>
                  <input
                    type='text'
                    placeholder='ENTER COUPON CODE'
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                  />
                  <button
                    className={
                      enabled
                        ? classes.disabledButton
                        : classes.notDisabledButton
                    }
                    disabled={enabled}
                    onClick={() => handleDiscount(discount)}
                  >
                    APPLY
                  </button>
                </div>
                {enabled ? (
                  <div className={classes.discountTextBlock}>
                    <div className={classes.addedDicountText}>
                      Your {appliedDiscount}% discount code has been applied.
                    </div>
                    <div
                      onClick={() => handleRemove()}
                      className={classes.removeDiscount}
                    >
                      (Remove)
                    </div>
                  </div>
                ) : null}
                {enabled && haveNotDiscountablePlot ? (
                  <div className={classes.notDiscountableText}>
                    Cart contains not discountable lands.
                  </div>
                ) : null}
                <div className={classes.sideBarCartTotalSumContainer}>
                  <div>
                    <h4 className={classes.H4fontStyleCourier}>Total</h4>
                    {enabled && setDiscountEth > 0 && (
                      <h4 className={classes.H4fontStyleCourierFinalPrice}>
                        Final price
                      </h4>
                    )}
                  </div>

                  <div className={classes.SideBarCartCountingPart}>
                    <p className={classes.SideBarRecentOrderETHTotal}>
                      {Number(landPlotsPriceinETH.toFixed(5))} ETH
                    </p>
                    {enabled && setDiscountEth > 0 && (
                      <p className={classes.discountPrice}>
                        {setDiscountEth.toFixed(5)} ETH saved
                      </p>
                    )}
                    {enabled && setDiscountEth > 0 && (
                      <p className={classes.SideBarRecentOrderETHTotal}>
                        {Number(setDiscountForETH.toFixed(5))} ETH
                      </p>
                    )}
                  </div>
                </div>

                <button
                  onClick={() => addToRecentOrdersList()}
                  className={classes.SideBarCartBuyButton}
                >
                  BUY
                </button>
              </>
            )}
            <div className='SideBarCustomDashedLine' />
            <div className={classes.SideBarRecentOrderContainer}>
              <div className='SideBarRecentOrderBlocks'>
                {order.map((feature: any, index: number) => (
                  <OrderContainer
                    feature={feature}
                    index={index}
                    key={index.toString()}
                    pricing={pricing}
                    onSingleBlockPop={singleBlockPop}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SideBar;
