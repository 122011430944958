import { useSelector } from "react-redux";
import { AppStore } from "../../../@redux/reducers/reducers";
import classes from "../SideBar.module.scss"

const CalculateLandplotPrice = ({ pricing, index, isExpiredDate, selectedPaymentType }: any) => {
    const order = useSelector((state: AppStore) => state.orders.orderList);
    const landPlotPriceToPayETH = order[index][0].paymentAmount / 1000000000000000000;
    const mars4TotalOrder = order[index][0].paymentAmountMars4Tokens / 1000000000000000000;
    const lanPlotPriceToPayUSD = (order[index][0].paymentAmountTokens / 1000000);

    const getPrice = () => {
      if (selectedPaymentType === 'eth') {
        return `${Number(landPlotPriceToPayETH.toFixed(5))}`;
      }

      if (selectedPaymentType === 'mars4') {
        return `${Number(mars4TotalOrder.toFixed(2))}`;
      }

      if (selectedPaymentType === 'usdt' || selectedPaymentType === 'usdc') {
        return `${Number(lanPlotPriceToPayUSD.toFixed(2))}`;
      }

      return '';
    }

    return (
        <div className={classes.sideBarCartTotalSumContainer}>
            <div >
                <h4 className={classes.H4fontStyleCourier}>Total</h4>
            </div>
            <div className={classes.SideBarCartCountingPart}>
                <p className={classes.SideBarRecentOrderETHTotal}>
                  {isExpiredDate ? '-----' : getPrice()}&nbsp;
                  <span>{selectedPaymentType}</span>
                </p>
            </div>
        </div>
    );
};


export default CalculateLandplotPrice;

