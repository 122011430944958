import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {connect, disconnect} from "../../helpers/provider";
import { AppStore } from "../../@redux/reducers/reducers";
import LogoImg from "../../assets/Images/Mars4_Logo_White.svg";
import { NavLink, useHistory } from "react-router-dom";
import { isburgerMenu, sidebarStatus } from "../../@redux/actions";
import useWindowSize from "../../utils/windowSize";
import classes from "./Navigation.module.scss"
import ShoppingCart from "../../assets/Images/shopping-cart.svg"
import ExternalLink from "../../assets/Images/external-link.svg"
import {routes} from "../../config/Router/Router";

interface NavigationProps {
    isCart: boolean;
}

const LAST_SYMBOLS_BREAKPOINT = 450;
const HOW_TO_BUY_BREAKPOINT = 500;

const Navigation = (props: NavigationProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const navigationBreakPoint = 980;
    //Data from redux state
    const sitebarStatus = useSelector((state: AppStore) => state.sidebarStatus);
    const burgerStatus = useSelector((state: AppStore) => state.burgerStatus);
    const cart = useSelector((state: AppStore) => state.cart.addedItems);
    const userAddress = useSelector((state: AppStore
    ) => state.user.userAddress);
    //Window width/height tracker
    const { width } = useWindowSize();
    //Tracking sidebar status
    const firstSymbols = userAddress?.substring(0, 6);
    const lastSymbols = userAddress?.substring(38);
    const pathName = history.location.pathname;

    const connectButton = () => {
        dispatch(isburgerMenu(false));
        dispatch(sidebarStatus(false));
        connect();
    }

    const handleDisconnectClick = () => {
        dispatch(isburgerMenu(false));
        dispatch(sidebarStatus(false));
        disconnect();
    }

    return (
        <>
            <div className={classes.container}>
                <nav id="navigation" className={classes.wrapper} >
                    <div className={classes.logoSectionBlock}>
                        <a href="https://app.mars4.me">
                            <img src={LogoImg} className="" alt="Mars4 Logo" />
                        </a>
                    </div>
                    {width > navigationBreakPoint ?
                      <div id="mainListDiv" className={classes.linkSectionBlock}>
                          <div className={classes.listItems}>
                              <NavLink activeClassName={pathName === routes.landing ? classes.selected : ""} className={classes.listItem} style={{ textDecoration: 'none', }} to={routes.landing}>
                                  <p className={classes.aStyleing} >
                                      BUY LAND
                                  </p>
                              </NavLink >
                              {userAddress ?
                                <>
                                    <li
                                      className={width > 690 ? "text-white d-flex" : "d-none"
                                      }
                                      id="enable-eth-button"
                                    >
                                        <NavLink activeClassName={classes.selected} className={classes.listItem} style={{ textDecoration: 'none' }} to={routes.empire}>
                                            <p className={classes.aStyleing} >
                                                MY EMPIRE
                                            </p>
                                        </NavLink>
                                    </li>
                                </>
                                : null}

                              <NavLink activeClassName={classes.selected} className={classes.listItem} style={{ textDecoration: 'none', }} to={routes.lands}>
                                  <p className={classes.aStyleing} >
                                      LANDS
                                  </p>
                              </NavLink >
                              <a target="_blank"  rel="noreferrer" className={classes.listItem} style={{ textDecoration: 'none' }} href="https://www.mars4.me/post/how-to-buy-mars4-nfts">
                                  <p className={classes.aStyleing1} >
                                      HOW TO BUY
                                      <img
                                        src={ExternalLink}
                                        className={classes.externalLink}
                                        alt="Mars4.me External Link logo"
                                      />
                                  </p>
                              </a>
                              <div className={classes.walletBlock}>
                                  {!userAddress ? (
                                    <button
                                      key={"connect"}
                                      onClick={connectButton}
                                      className={classes.listItemButton}
                                      id="enable-eth-li">
                                        <p className={classes.walletConnect}>CONNECT WALLET</p>
                                    </button>
                                  ) : (
                                    <>
                                        <li className={width > 690 ? "text-white d-flex" : "d-none"} id="enable-eth-button">
                                            <p className={classes.aStyleing} >
                                                {firstSymbols}...{lastSymbols}
                                            </p>
                                        </li>

                                    </>
                                  )}

                              </div>
                              <div
                                onClick={() => dispatch(sidebarStatus(!sitebarStatus))
                                }
                                className={`text-white d-flex cart align-self-center ms-4 position-relative ${sitebarStatus ? "active" : ""}`}
                                id="enable-eth-button"
                              >
                                  <div className={classes.bubble}>
                                      <label className={classes.dynamicCartNumber}>{cart.length}</label>
                                  </div>
                                  <img
                                    src={ShoppingCart}
                                    className={classes.shoppingCart}
                                    alt="Mars4.me shopping cart logo"
                                  />
                              </div>
                              {!!userAddress && (
                                <button
                                  key={"connect"}
                                  onClick={handleDisconnectClick}
                                  className={classes.disconnectButton}
                                >
                                    <p className={classes.walletConnect}>DISCONNECT</p>
                                </button>
                              )}
                          </div>
                      </div>
                      :
                      <React.Fragment>
                          <div className={classes.userBurgerAddressBlock}>
                              <li
                                className={(width < navigationBreakPoint && userAddress) ? "wallet text-white d-flex px-2 py-2" : "d-none"
                                }
                                id="enable-eth-button"
                              >
                                  <NavLink className={classes.listItem} style={{ textDecoration: 'none' }} to={routes.empire}>
                                      <p className={classes.aStyleing2} >
                                          {firstSymbols}...{width > LAST_SYMBOLS_BREAKPOINT && lastSymbols}
                                      </p>
                                  </NavLink>
                              </li>
                          </div>
                          <div className={classes.lowWdthNavi}>
                              {width > HOW_TO_BUY_BREAKPOINT && (
                                  <a className={classes.listItem} style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer" href="https://www.mars4.me/post/how-to-buy-mars4-nfts">
                                      <p className={classes.aStyleing1} >
                                          {width > HOW_TO_BUY_BREAKPOINT && 'HOW TO BUY'}
                                          <img
                                            src={ExternalLink}
                                            className={classes.shoppingCart}
                                            alt="Mars4.me External Link"

                                          />
                                      </p>
                                  </a>
                                )
                              }

                              <div
                                onClick={() => dispatch(sidebarStatus(!sitebarStatus))
                                }
                                className={width < navigationBreakPoint ? `text-white d-flex cart align-self-center mr-5 position-relative ${sitebarStatus ? "active" : ""
                                }` : "d-none"}
                                id="enable-eth-button"
                              >
                                  <div className={classes.bubble}>
                                      <label className={classes.dynamicCartNumber}>{cart.length}</label>
                                  </div>
                                  <img
                                    src={ShoppingCart}
                                    className={classes.shoppingCart}
                                    alt="Mars4.me shopping cart logo"

                                  />
                              </div>
                              <div onClick={() => dispatch(isburgerMenu(!burgerStatus)) && dispatch(sidebarStatus(false))} className={width > navigationBreakPoint ? "d-none" : classes.burger}>
                                  <span className={!burgerStatus ? classes.stickOne : classes.active}></span>
                                  <span className={!burgerStatus ? classes.stickTwo : classes.active2}></span>
                                  <span className={!burgerStatus ? classes.stickThree : classes.active3}></span>
                              </div>

                          </div>
                      </React.Fragment>
                    }

                </nav>

                {(burgerStatus && width < navigationBreakPoint) ?
                  <div className={classes.burgerItems}>
                      <a className={pathName === routes.landing ?  classes.selected : classes.listItem}  style={{ textDecoration: 'none', }} href={routes.landing}>
                          <p className={classes.aStyleing} >
                              BUY LAND
                          </p>
                      </a>
                      <NavLink activeClassName={classes.selected} className={classes.listItem} style={{ textDecoration: 'none', }} to={routes.lands}>
                          <p className={classes.aStyleing} >
                              LANDS
                          </p>
                      </NavLink>
                      <a className={classes.listItem} style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer" href="https://www.mars4.me/post/how-to-buy-mars4-nfts">
                          <p className={classes.aStyleing1} >
                              HOW TO BUY
                              <img
                                src={ExternalLink}
                                className={classes.shoppingCart}
                                alt="Mars4.me External Link"

                              />
                          </p>
                      </a>

                      {!userAddress ?
                        <div className={classes.walletBlock}>
                            <button
                              key={"connect"}
                              onClick={connectButton}
                              className={
                                  classes.listItemButton
                              }
                              id="enable-eth-li"
                            >
                                <p className={classes.walletConnect}>CONNECT WALLET</p>
                            </button>
                        </div>
                        :
                        <>
                            <li
                              className="text-white d-flex"
                              id="enable-eth-button"
                            >
                                <NavLink activeClassName={classes.selected} className={classes.listItem} style={{ textDecoration: 'none' }} to={routes.empire}>
                                    <p className={classes.aStyleing} >
                                        MY EMPIRE
                                    </p>
                                </NavLink>
                            </li>
                        </>
                      }
                      {!!userAddress && (
                        <div className={classes.walletBlock}>
                            <button
                              key={"connect"}
                              onClick={handleDisconnectClick}
                              className={classes.listItemButton}
                            >
                                <p className={classes.walletConnect}>DISCONNECT</p>
                            </button>
                        </div>
                      )}
                  </div>
                  : null
                }
            </div >
            {width < HOW_TO_BUY_BREAKPOINT && (
              <div className={classes.howToBuyNFT}>
                  <a className={classes.listItem} style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer" href="https://www.mars4.me/post/how-to-buy-mars4-nfts">
                      <p className={classes.aStyleing1} >
                          HOW TO BUY NFTS
                          <img
                            src={ExternalLink}
                            className={classes.externalLink}
                            alt="Mars4.me External Link"

                          />
                      </p>
                  </a>
              </div>
            )}
        </>

    );
};

export default Navigation;
