import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setTotalPage } from "../../../../@redux/actions/filterAction";
import { cn } from "../../../../hooks/classJoin";
import classes from "./Pagination.module.scss"


const Paginations = (props: { totalRecords: number; pageLimit: number; pageNeighbours: number; onPageChanged: Function; currentPage: number; }) => {
  const {
    totalRecords,
    pageLimit,
    pageNeighbours,
    onPageChanged,
    currentPage
  } = props;
  const [totalPages, setTotalPages] = useState(1);
  const dispatch = useDispatch();
  useEffect(() => {
    if (totalRecords !== 0) {
      setTotalPages(Math.ceil(totalRecords / pageLimit));
    } else {
      setTotalPages(1)
    }
  }, [pageLimit, totalRecords]);
  const LEFT_PAGE: any = '...';
  const RIGHT_PAGE: any = '...';
  const range = (from: number, to: number, step = 1) => {

    let i = from;
    const range = [];

    while (i <= to) {
      range.push(i);
      i += step;
    }

    return range;
  };
  useEffect(() => {
    if (totalPages !== 0) {
      dispatch(setTotalPage(totalPages))
    } else {
      dispatch(setTotalPage(1))
    }
  }, [totalPages])
  
  const fetchPageNumbers = () => {
    const totalNumbers = pageNeighbours * 2 + 1;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {

      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

      let pages = range(startPage, endPage);

      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case (hasLeftSpill && !hasRightSpill): {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // // handle: (1) {2 3} [4] {5 6} > (10)
        case (!hasLeftSpill && hasRightSpill): {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // // handle: (1) < {4 5} [6] {7 8} > (10)
        case (hasLeftSpill && hasRightSpill):
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }
      return [1, ...pages, totalPages];
    }
    return range(1, totalPages);
  };

  const pages = fetchPageNumbers() || [];

  return (
    <nav aria-label="Mars4 LandPlots">
      <ul className={classes.pagination}>
        <li className={classes.pageItem}>
          <a
            href="/"
            className={classes.pageLink}
            aria-label="Previous"
            onClick={(e) => onPageChanged(e, currentPage - 1)}
          >
            <span className={classes.srOnly}>&lt;</span>
          </a>
        </li>
        {pages.map((page, index) => {
          if (page === LEFT_PAGE)
            return (
              <li key={index} className={classes.pageItem}>
                <div
                  className={classes.pageLink}
                >
                  <span className={classes.srOnly}>...</span>
                </div>
              </li>
            );

          if (page === RIGHT_PAGE)

            return (
              <li key={index} className={classes.pageItem}>
                <div
                  className={classes.pageLink}
                >
                  <span className={classes.srOnly}>...</span>
                </div>
              </li>
            );



          return (
            <li
              key={index}
              className={classes.pageItem}
            >
              <a
                className={cn(classes.pageLink, classes[currentPage === page ? "active" : ""])}
                href="/"
                onClick={(e) => onPageChanged(e, page)}
              >
                {page}
              </a>
            </li>

          );
        })}
        <li className={classes.pageItem}>
          <a
            href="/"
            className={classes.pageLink}
            aria-label="Next page"
            onClick={(e) => onPageChanged(e, currentPage + 1)}
          >
            <span className={classes.srOnly}>&gt;</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};
export default Paginations;
