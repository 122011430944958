// React/Redux/axios import
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Imported Components/utils
import Navigation from "../../components/Navigation/Navigation";
import Dropdown from "./components/DropDown/DropDown";
import LandPlot from "../Gallery/components/LandPlot/LandPlot";
// Imported Action/Reducer
import { AppStore } from "../../@redux/reducers/reducers";
import { isburgerMenu, sidebarStatus } from "../../@redux/actions";
// Styles
import classes from "./Gallery.module.scss"
import Paginations from "./components/Pagination/Pagination";
import Footer from "../../components/Footer/Footer";
import SideBar from "../../components/SideBar/SideBar";
import useWindowPosition from "../../utils/windowScroll";
import { SampleComponent, SERVER_URL } from "../..";
import axios, { AxiosResponse } from "axios";

const Gallery = () => {
  // Variables
  let LIMIT = 20;
  // let totalRecords = 99888;
  const currentPages = useSelector((state: AppStore) => state.appliedFilters.currentPage);
  // React/redux data tools
  const dispatch = useDispatch();
  const [pricing, setPricing] = useState<React.ComponentState>("");
  const [currentPage, setCurrentPage] = useState(currentPages);
  const sitebarStatus = useSelector((state: AppStore) => state.sidebarStatus);
  const totalProducts = useSelector((state: AppStore) => state.products);
  const totalSortProducts = useSelector((state: AppStore) => state.sortProducts);
  const {totalRecords, input} = useSelector((state: AppStore) => state.appliedFilters);
  const  totalPages = useSelector((state: AppStore) => state.appliedFilters.totalPages);

  // Window Scroll dynamic resizing function and check
  useEffect(() => {
    dispatch(isburgerMenu(false));
    dispatch(sidebarStatus(false))
  }, [])

  const scrollPosition = useWindowPosition()
  if (scrollPosition !== 0 && sitebarStatus) {
    dispatch(sidebarStatus(false))
  }
  // Pagination
  useEffect(() => {
    // dispatch(filterLandplotByInputType(input, currentPage, "name", ""));
    axios.get(`${SERVER_URL}/pricing`).then((data: AxiosResponse) => setPricing(data.data))
      .catch(error => console.log(error));

  }, [input, currentPage])

  const onPageChanged = useCallback(
    (event, page) => {
      event.preventDefault();
      if (page !== 0 && page < totalPages+1) {
        setCurrentPage(page);
      }
    },
    [setCurrentPage,totalPages]
  );

  SampleComponent()
  return (
    <div className={classes.container}>
      <Navigation isCart={true} />
      <div className={classes.wrapper}>
        {sitebarStatus ? <SideBar pricing={pricing} /> : null}
        <Dropdown  onPageChanged={onPageChanged} currentPage={currentPage} />
        <LandPlot totalRecords={ input === "" ? totalRecords : totalSortProducts.length} totalProducts={totalProducts} />
        <Paginations totalRecords={ input === "" ? totalRecords : totalSortProducts.length} pageLimit={LIMIT} pageNeighbours={1} onPageChanged={onPageChanged} currentPage={currentPage} />
      </div>
      <Footer />
    </div>
  );
};

export default Gallery;