import { useEffect, useState } from "react";
import classes from "../SideBar.module.scss"
const ExpireTimer = ({ expireDate, setIsExpiredDate }: any) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(expireDate.expiresAt) - +new Date();
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        // days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }else {

      setIsExpiredDate(true)
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<any>(calculateTimeLeft());
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => {
      clearTimeout(timer);
    }
  });
  const timerComponents: any[] = [];
  timerComponents.push(
    <span key={expireDate.TOKENID}>
      {(timeLeft.hours > 9 ? timeLeft.hours : '0' + timeLeft.hours) + ':' +
        (timeLeft.minutes > 9 ? timeLeft.minutes : '0' + timeLeft.minutes) + ':'
        + (timeLeft.seconds > 9 ? timeLeft.seconds : '0' + timeLeft.seconds)}
    </span>
  )
  return (
    <div className={classes.expireTimer}>
      {!(Object.keys(timeLeft).length === 0) ? timerComponents : <span >Reservation cancelled.</span>}
    </div>
  );

}
export default ExpireTimer;