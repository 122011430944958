//styles
import classes from "./Footer.module.scss";
//img
import marsLogotip from "../../assets/Images/Mars_Logo_Footer_Section.svg"
import linkedin from "../../assets/Images/LinkedIn.svg"
import youtube from "../../assets/Images/YouTube.svg"
import twitter from "../../assets/Images/Twitter.svg"
import facebook from "../../assets/Images/Facebook.svg"
import telegram from "../../assets/Images/Telegram.svg"
import discord from "../../assets/Images/Discord.svg"
import medium from "../../assets/Images/Medium.svg"
import React from "react";
import useWindowSize from "../../utils/windowSize";

interface socialLinksData {
    name:string;
    url:string;
    image:string;
}

const Footer = () => {
    const socials = [
        {
            name: "LinkedIn",
            url: "https://www.linkedin.com/company/mars4/",
            image: linkedin
        },
        {
            name: "FaceBook",
            url: "https://www.facebook.com/mars4.me",
            image: facebook
        },
        {
            name: "Twitter",
            url: "https://twitter.com/MARS4_me",
            image: twitter
        },
        {
            name: "YouTube",
            url: "https://www.youtube.com/channel/UCdzyC4xEyG5T4o0q4Ubh6VQ",
            image: youtube
        },
        {
            name: "Telegram",
            url: "https://t.me/mars4me_official",
            image: telegram
        },
        {
            name: "Discord",
            url: "https://discord.com/invite/bZaCbCzg6t",
            image: discord
        },
        {
            name: "medium",
            url: "https://mars4-me.medium.com/",
            image: medium
        },
    ];
    const { width } = useWindowSize();

    return (
        <React.Fragment>
            <div className={classes.topContainer}>
                <img className={classes.marsLogo} src={marsLogotip} alt="Mars4Me Project Logotip" />
                <p className={classes.emailBox}>info@mars4.me</p>
                <div >
                    {socials.map((social: socialLinksData, index: number) => {
                        return (
                            <a key={index} href={social.url}>
                                <img className={classes.socialLinkWrapper} src={social.image} alt={social.name} />
                            </a>
                        )
                    })}
                </div>
            </div >
            <div className={classes.bottomContainer}>
                {width < 850 ?
                    <div>
                        <button className={classes.instructionButton}><a target="_blank" rel="noreferrer" href="https://www.mars4.me/instructions">Wallet Info</a></button>
                        <button className={classes.instructionButton}><a target="_blank" rel="noreferrer" href="https://www.mars4.me/post/how-to-buy-mars4-nfts">How to buy land</a></button>
                    </div> :
                    null
                }
                <span>
                    <p className={classes.copyRights}>&copy;2022 by Mars4</p>
                    {width > 850 ?
                        <>
                            <button className={classes.instructionButton}><a target="_blank" rel="noreferrer" href="https://www.mars4.me/instructions">Wallet Info</a></button>
                            <button className={classes.instructionButton}><a target="_blank" rel="noreferrer" href="https://www.mars4.me/post/how-to-buy-mars4-nfts">How to buy land</a></button>
                        </>
                        : null}
                    <a target="_blank" rel="noreferrer" href="https://www.mars4.me/terms-conditions" className={classes.termsAndConditions}>Terms & Conditions</a>
                </span>
            </div>
        </React.Fragment>

    );
};

export default Footer;