import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import axios, { AxiosResponse } from "axios";
import { loadModules } from "esri-loader";
// import MyFeatureLayer from "../../hooks/layer";
import { useDispatch, useSelector } from "react-redux";
import { AppStore } from "../../@redux/reducers/reducers";
import classes from "./MarsSceneView.module.scss"
//Components
// import Navigation from "../Navigation/Navigation";
import "react-toastify/dist/ReactToastify.css";
import { cartLoadingStatus, fetchedOwnerPlots, initialReserved, isburgerMenu, removeItemAddedPopup, reservedLandplots, sidebarStatus, trackOrderClick } from "../../@redux/actions";
import MarsLandplots from "../MarsLandplots/MarsLandplots";
import Navigation from "../Navigation/Navigation";
import SideBar from "../SideBar/SideBar";
import useWindowSize from "../../utils/windowSize";
import { SERVER_URL } from "../..";
import CustomPopup from "../../pages/LandingPage/components/CustomPopUp/CustomPopUp";
import {useLocation, useParams} from "react-router";
import queryString from 'query-string';

type PartnerPromo = {
  promo: string | undefined,
}

const MarsSceneView = () => {
  const dispatch = useDispatch()
  const {search} = useLocation();
  const sceneViewRef = useRef<HTMLDivElement>(null);
  const ethAccount = useSelector((state: AppStore) => state.user.userAddress);
  const reserevedPlots = useSelector((state: AppStore) => state.reserved);
  const [sceneView, setSceneView] = useState<null>(null);
  const [layer, setlayer] = useState<React.ComponentState>(null)
  const [pricing, setPricing] = useState<React.ComponentState>("");

  const parsedSearch = queryString.parse(search);
  const parsedUtmCampaign = parsedSearch['utm_campaign'];

  const { promo } = useParams<PartnerPromo>();

  useEffect(() => {
    dispatch(trackOrderClick(true));
    dispatch(isburgerMenu(false));
    dispatch(sidebarStatus(false))
    dispatch(cartLoadingStatus(true))
    dispatch(initialReserved())
  }, [])

  // let fetchMyPLots: Function;

  useEffect(() => {
    // fetchMyPLots = () => {
      ethAccount &&
        axios
          .get(`${SERVER_URL}/tokens?filter[owner]=${ethAccount}`)
          .then((plots) => dispatch(fetchedOwnerPlots(plots.data)))
          .catch(error => console.log(error));
    // }
  }, [ethAccount, dispatch]);

  useEffect(() => {
    dispatch(removeItemAddedPopup(false))
    axios
      .get(`${SERVER_URL}/tokens?filter[reserved]=1`)
      .then((plots: AxiosResponse) => {
        if (plots.data.length !== reserevedPlots.length)
          dispatch(reservedLandplots(plots))
      })
      .catch(error => console.log(error));
  })

  const updatePricing = useCallback(() => {
    axios.get(`${SERVER_URL}/pricing`).then((data: AxiosResponse) => setPricing(data.data))
      .catch(error => console.log(error));
  }, []);

  useEffect(() => {
    // setInterval(fetchMyPLots, 30000)
    setInterval(updatePricing, 150000)
    updatePricing()
    // fetchMyPLots()

  }, [updatePricing])

  // const monitorOrder = (orderId: string) => {
  //   axios.get(`${SERVER_URL}/orders/${orderId}`).then((data: AxiosResponse) => {
  //     // orders.filter((order: any) => order.map((item: any) => item.orderId !== orderId ? item : { ...item, status: data.data.status }))
  //     orders.filter((order: any) => order.map((item: any) => console.log(item)))
  //     if (data.data.status === 2) fetchMyPLots();
  //     if (new Date().getTime() - new Date(data.data.createdAt).getTime() < 600000 && data.data.status != 2) {
  //       setTimeout(function () {
  //         monitorOrder(orderId)
  //       }, 20000)
  //     }
  //   })
  //     .catch(error => console.log(error));
  // }

  const { width } = useWindowSize();

  useMemo(() => {
    loadModules([
      "esri/Map",
      "esri/views/SceneView",
      "esri/layers/ElevationLayer",
      "esri/layers/TileLayer",
      "esri/layers/FeatureLayer",
      "esri/symbols/PointSymbol3D",
      "esri/symbols/ObjectSymbol3DLayer",
      "esri/config"
    ]).then(
      ([
        Map,
        SceneView,
        ElevationLayer,
        TileLayer,
        FeatureLayer,
        PointSymbol3D,
        ObjectSymbol3DLayer,
        esriConfig
      ]) => {

        esriConfig.apiKey = process.env.REACT_APP_ARCGIS_API_KEY

        const marsElevation = new ElevationLayer({
          url: "https://astro.arcgis.com/arcgis/rest/services/OnMars/MDEM200M/ImageServer",
          copyright:
            "NASA, ESA, HRSC, Goddard Space Flight Center, USGS Astrogeology Science Center, Esri",
        });

        const marsImagery = new TileLayer({
          url: "https://astro.arcgis.com/arcgis/rest/services/OnMars/MDIM/MapServer",
          title: "Imagery",
          copyright: "USGS Astrogeology Science Center, NASA, JPL, Esri",
        });

        const marsSceneView = new SceneView({
          tilingSchemeLocked: true,
          map: new Map({
            ground: {
              layers: [marsElevation],
            },
            layers: [marsImagery],
          }),
          container: sceneViewRef.current,
          qualityProfile: "high",
          spatialReference: {
            wkid: 104971,
          },
          highlightOptions: {
            color: "#000000",
            haloColor: "#000000",
            haloOpacity: 0.5,
            fillOpacity: 0.2,
          },
          camera: {
            position: {
              x: 27.63423,
              y: -6.34466,
              z: 1281525.766,
              spatialReference: 104971,
            },
            heading: 332.28,
            tilt: 37.12,
          },
        });

        const objLayer = new FeatureLayer({
          portalItem: {
            id: "aacd27f54e6d4f5592aa18fe561eab68"
          },
          outFields: ["*"],
          renderer: {
            type: "unique-value", // autocasts as new UniqueValueRenderer()
            field: "TYPE",
            uniqueValueInfos: [
              {
                value: "BILLBOARD",
                symbol: new PointSymbol3D({
                  symbolLayers: [
                    new ObjectSymbol3DLayer({
                      anchor: "relative",
                      anchorPosition: { x: 0, y: 0, z: -0.5 },
                      resource: {
                        // the dependencies referenced in the gltf file will be requested as well
                        href: "/models/billboard/scene.gltf",
                      },
                      height: 64000,
                    }),
                  ],
                }),
              },
            ],
            visualVariables: [
              {
                type: "rotation", // indicates that symbols should be rotated based on value in field
                field: "ANGLE", // field containing aspect values
                rotationType: "geographic",
              },
            ],
          },
        });
        marsSceneView.map.layers.add(objLayer);

        // const sectorLayer = new FeatureLayer({
        //   url: 'https://services3.arcgis.com/NN6OJUvmvEQPMakL/arcgis/rest/services/m4m_sectors/FeatureServer/0',
        //   apiKey: 'AAPK55eed9470155469f85b5134e3008200dHeHwFxshnUolxhUfpRAHm9FPX07WsQJHNn5I-nKXM7EGg9y4V_i2KWwD7hxQbdDt',
        //   outFields: ["*"],
        //   renderer: {
        //     type: "simple",  // autocasts as new SimpleRenderer()
        //     symbol: {
        //       type: "simple-fill",  // autocasts as new SimpleFillSymbol()
        //       color: [ 255, 128, 0, 0.1 ],
        //       outline: {  // autocasts as new SimpleLineSymbol()
        //         width: 1,
        //         color: "white"
        //       }
        //     }
        //   },
        //   labelingInfo: {
        //     symbol: {
        //       type: "text",  // autocasts as new TextSymbol()
        //       color: "white",
        //       haloSize: 1,
        //       font: {  // autocast as new Font()
        //          family: "Noto Sans",
        //          size: 10
        //        }
        //     },            
        //     labelExpressionInfo: {
        //       expression: "$feature.SECTOR_ID"
        //     },
        //     maxScale: 0,
        //     minScale: 1000000        
        //   }
        // });
        // marsSceneView.map.layers.add(sectorLayer);
        
        setSceneView(marsSceneView);
      }
    );
  }, []);
  return (
    <>
      <div className={classes.container}>
        <Navigation isCart={false} />
        <div id="" className="BodyStyle">
          <div id="map" ref={sceneViewRef} className="MapStyle" />
          <MarsLandplots
            setlayer={setlayer}
            url="https://services3.arcgis.com/NN6OJUvmvEQPMakL/arcgis/rest/services/data/FeatureServer/0"
            // updatePricing={updatePricing}
            sceneView={sceneView}
          />
          <SideBar
            sceneView={sceneView}
            layer={layer}
            pricing={pricing}
            promo={parsedUtmCampaign ? parsedUtmCampaign : promo}
          />
        </div>
      </div>
      {width < 840 ? <CustomPopup /> : null}
    </>
  );
};
export default MarsSceneView;
