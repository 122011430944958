import { AxiosResponse } from 'axios';
import { Cart, Discount } from '../../types/cart';
import { OrderAxiosResponseData } from '../../types/order';

export enum AppActions {
  SET_USER_DATA = 'SET_USER_DATA',
  SET_USER_ADDRESS = 'SET_USER_ADDRESS',
  SET_USER_CHAIN_ID = 'SET_USER_CHAIN_ID',
  SET_VOLUME = 'SET_VOLUME',
  SET_ADD_TO_CART = 'SET_ADD_TO_CART',
  REMOVE_FROM_CART = 'REMOVE_FROM_CART',
  REMOVE_ALL_CART_ITEMS = 'REMOVE_ALL_CART_ITEMS',
  SET_ADD_TO_ORDER = 'SET_ADD_TO_ORDER',
  REMOVE_ALL_ORDER_ITEMS = 'REMOVE_ALL_ORDER_ITEMS',
  GET_OWNER_PLOTS = 'GET_OWNER_PLOTS',
  SET_RESERVED_PLOTS = 'SET_RESERVED_PLOTS',
  TRACK_ORDER_CLICK = 'TRACK_ORDER_CLICK',
  CHANGE_STATUS = 'CHANGE_STATUS',
  CHNAGE_SIDEBAR_STATUS = 'CHANGE_SIDEBAR_STATUS',
  CHNAGE_CART_LOADING_STATUS = 'CHNAGE_CART_LOADING_STATUS',
  CHNAGE_ORDER_LOADING_STATUS = 'CHNAGE_ORDER_LOADING_STATUS',
  CHNAGE_BURGER_STATUS = 'CHNAGE_BURGER_STATUS',
  FILTER_CLEAR_DATA_SUCCESS = 'FILTER_CLEAR_DATA_SUCCESS',
  CHNAGE_ADDED_CART_STATUS = 'CHNAGE_ADDED_CART_STATUS',
  SORT_BY_VALUE = 'SORT_BY_VALUE',
  SET_DISCOUNT = 'SET_DISCOUNT',
  SET_TOTAL_PAGES = 'SET_TOTAL_PAGES',
  SET_INITIAL_LOAD_RESERVED = 'SET_INITIAL_LOAD_RESERVED',
  REMOVE_DISCOUNT = 'REMOVE_DISCOUNT',
}

export function setUserData(data?: {
  userChainId?: number;
  userAddress?: string;
}) {
  return {
    type: AppActions.SET_USER_DATA,
    data: { userChainId: data?.userChainId, userAddress: data?.userAddress },
  };
}

export function setUserAddress(userAddress: string | undefined) {
  return {
    type: AppActions.SET_USER_ADDRESS,
    data: { userAddress },
  };
}

export function setUserChainId(userChainId: number | undefined) {
  return {
    type: AppActions.SET_USER_CHAIN_ID,
    data: { userChainId },
  };
}
export function setVolume(volume: number) {
  return {
    type: AppActions.SET_VOLUME,
    data: { volume },
  };
}
export function removeFromCart(i: string) {
  return {
    type: AppActions.REMOVE_FROM_CART,
    i,
  };
}
export function removeAllCartItems() {
  return {
    type: AppActions.REMOVE_ALL_CART_ITEMS,
  };
}
export function removeAllOrderItems(index: number) {
  return {
    type: AppActions.REMOVE_ALL_ORDER_ITEMS,
    index,
  };
}
export function addToCart(cart: Cart) {
  return {
    type: AppActions.SET_ADD_TO_CART,
    cart,
  };
}
export function addToOrder(cart: Cart[], data: OrderAxiosResponseData) {
  return {
    type: AppActions.SET_ADD_TO_ORDER,
    cart,
    data,
  };
}

export function fetchedOwnerPlots(plot: string) {
  return {
    type: AppActions.GET_OWNER_PLOTS,
    plot,
  };
}

export function reservedLandplots(plots?: any) {
  return {
    type: AppActions.SET_RESERVED_PLOTS,
    plots,
  };
}
export function initialReserved() {
  return {
    type: AppActions.SET_INITIAL_LOAD_RESERVED,
  };
}
export function trackOrderClick(option: boolean) {
  return {
    type: AppActions.TRACK_ORDER_CLICK,
    option,
  };
}
export function changeStatus(option: string) {
  return {
    type: AppActions.CHANGE_STATUS,
    option,
  };
}

export function sidebarStatus(status: boolean) {
  return {
    type: AppActions.CHNAGE_SIDEBAR_STATUS,
    status,
  };
}
export function isburgerMenu(status: boolean) {
  return {
    type: AppActions.CHNAGE_BURGER_STATUS,
    status,
  };
}
export function cartLoadingStatus(status: boolean) {
  return {
    type: AppActions.CHNAGE_CART_LOADING_STATUS,
    status,
  };
}
export function orderLoadingStatus(status: boolean) {
  return {
    type: AppActions.CHNAGE_ORDER_LOADING_STATUS,
    status,
  };
}
export function removeItemAddedPopup(status: boolean) {
  return {
    type: AppActions.CHNAGE_ADDED_CART_STATUS,
    status,
  };
}

export function setDiscountForUser(data: Discount) {
  return {
    type: AppActions.SET_DISCOUNT,
    data,
  };
}
export function removeDiscount() {
  return {
    type: AppActions.REMOVE_DISCOUNT,
  };
}
