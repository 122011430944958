import { ethers } from "ethers";
import { API } from "../core/api/API";
import { setUserAddress, setUserChainId, setUserData } from "../@redux/actions";
import web3Modal from "./web3modal";
import store from "../@redux/store";

export let provider: ethers.providers.Web3Provider | null = null;
let currentWeb3Provider: any;

export const initProvider = async (web3Provider: any) => {
  provider = new ethers.providers.Web3Provider(web3Provider);
  currentWeb3Provider = web3Provider;
  store.dispatch(
    setUserData({
      userChainId: await API.Account.getChainId(),
      userAddress: await API.Account.getAccount(),
    })
  );

  web3Provider.on("accountsChanged", async (data: string[]) => {
    store.dispatch(setUserAddress(await API.Account.getAccount()));
    if (!data[0]) {
      onDisconnect();
    }
  });
  web3Provider.on("chainChanged", async () => {
    provider = new ethers.providers.Web3Provider(web3Provider);
    store.dispatch(setUserChainId(await API.Account.getChainId()));
  });


  web3Provider.on("disconnect", async (_: number, reason: string) => {
    console.warn(reason);
    onDisconnect();
  });
};

export const connect = async () => {
  try {
    // @ts-ignore
    window.esk('event', 'ConnectWallet');
    const provider3 = await web3Modal?.connect();
    await initProvider(provider3);
  } catch (e) {
    console.log(e)
  }
};

export const disconnect = async () => {
  if ((provider?.provider as any).disconnect) {
    (provider?.provider as any).disconnect();
  } else {
    onDisconnect();
  }
};

const onDisconnect = async () => {
  await web3Modal?.clearCachedProvider();
  store.dispatch(setUserData());
  provider = null;
  currentWeb3Provider?.removeAllListeners();
};
