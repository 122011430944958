import { AnyAction } from 'redux';
import { AppActions } from '../actions';
import { Cart, CartReducer } from '../../types/cart';
import { FilterActions } from '../actions/filterAction';
import { Order, OrderReducer } from '../../types/order';
import { Filter, FilterProducts } from '../../types/filter';
import _ from 'lodash';
import { SearchById } from '../../components/Landplots/LandPlots';
export interface AppStore {
  sortProducts: SearchById[];
  user: {
    userAddress: string;
    userChainId: number;
  };
  volume?: number;
  cart: CartReducer;
  orders: OrderReducer;
  reserved: any;
  loaded: string;
  appliedFilters: Filter;
  products: FilterProducts[];
  sidebarStatus: boolean;
  burgerStatus: boolean;
}

const initialState: AppStore = {
  volume: 10,
  user: {
    userAddress: '',
    userChainId: 0,
  },
  cart: {
    addedItems: [],
    removeAll: false,
    loading: true,
    itemAdded: false,
    discount: {
      code: '',
      discount: 0,
      enabled: false,
      expires_at: null,
    },
  },
  orders: {
    orderList: [],
    loading: true,
    removeAll: false,
  },
  appliedFilters: {
    filterByID: [],
    pageCount: 1,
    totalRecords: 0,
    input: '',
    selectSortValue: '',
    inputType: '',
    getSortSelectID: 0,
    currentPage: 1,
    totalPages: 1,
  },
  products: [],
  sortProducts: [],
  reserved: [],
  loaded: '',
  sidebarStatus: false,
  burgerStatus: false,
};

const rootReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case AppActions.SET_USER_DATA:
      return {
        ...state,
        user: {
          ...action.data,
        },
      };
    case AppActions.SET_USER_ADDRESS:
      return {
        ...state,
        user: {
          ...action.data,
        },
      };
    case AppActions.SET_USER_CHAIN_ID:
      return {
        ...state,
        user: {
          ...action.data,
        },
      };
    case AppActions.SET_VOLUME:
      return {
        ...state,
        user: {
          ...action.data,
        },
      };
    case AppActions.REMOVE_FROM_CART:
      return {
        ...state,
        cart: {
          ...state.cart,
          addedItems: [
            ...state!.cart.addedItems.filter(
              (item: Cart) => item.TOKENID !== action.i
            ),
          ],
        },
      };
    case AppActions.REMOVE_ALL_CART_ITEMS:
      return {
        ...state,
        cart: {
          ...state.cart,
          addedItems: [],
          removeAll: true,
        },
      };
    case AppActions.REMOVE_ALL_ORDER_ITEMS:
      return {
        ...state,
        orders: {
          ...state.orders,
          orderList: [
            ...state.orders.orderList.slice(0, action.index),
            ...state.orders.orderList.slice(action.index + 1),
          ],
          ordersToDelete: [
            ...state.orders.orderList[action.index].map(
              (itemIds: Cart) => itemIds.TOKENID
            ),
          ],
          loading: true,
          removeAll: true,
        },
      };
    case AppActions.SET_ADD_TO_CART:
      const productInCart = state.cart?.addedItems.find(
        (p: Cart) => p?.TOKENID === action?.cart.TOKENID
      );
      const newCart = { ...action.cart, selected: true };
      if (!productInCart) {
        return {
          ...state,
          cart: {
            ...state?.cart,
            addedItems: [...state.cart.addedItems, newCart],
            itemAdded: true,
          },
        };
      }
      return { ...state };

    case AppActions.SET_ADD_TO_ORDER:
      const newCartData = action.cart.map((item: Order) => {
        return _.extend(item, action.data);
      });
      return {
        ...state,
        orders: {
          ...state.orders,
          orderList: [...state.orders.orderList, newCartData],
          loading: true,
        },
        cart: {
          ...state.cart,
          addedItems: [],
          removeAll: true,
          discount: {
            code: '',
            discount: 0,
            enabled: false,
            expires_at: null,
          },
        },
      };

    case AppActions.TRACK_ORDER_CLICK:
      return {
        ...state,
        orders: {
          ...state.orders,
          loading: action.option,
          removeAll: false,
          ordersToDelete: undefined,
        },
        cart: {
          ...state.cart,
          removeAll: false,
          loading: false,
        },
      };
    case AppActions.SET_RESERVED_PLOTS:
      return {
        ...state,
        reserved: action.plots.data,
        loaded: 'OK',
      };
    case AppActions.SET_INITIAL_LOAD_RESERVED:
      return {
        ...state,
        loaded: 'OK',
      };

    case AppActions.CHANGE_STATUS:
      return {
        ...state,
        loaded: action.options,
        cart: {
          ...state.cart,
          loading: false,
        },
        orders: {
          ...state.orders,
          loading: false,
        },
      };
    // FILTER REDUCER PART
    case FilterActions.FILTER_BY_VALUE_SUCCESS:
      const selectedSortValue = action.payload.selectSortValue;
      const sortedProductsLength = action.payload.sortData.length;
      if (
        selectedSortValue === '' ||
        selectedSortValue === 'common' ||
        (selectedSortValue === 'unique' && action.payload.input === '')
      ) {
        return {
          ...state,
          appliedFilters: {
            ...state.appliedFilters,
            filterByID: action.payload.data,
            totalRecords: action.payload.totalRecords,
            inputType: action.payload.inputType,
            input: action.payload.input,
            selectSortValue: action.payload.selectSortValue,
            getSortSelectID: action.payload.getSortSelectID,
            currentPage: action.payload.currentPage,
          },
          sortProducts: action.payload.sortData,
        };
      } else {
        return {
          ...state,
          appliedFilters: {
            ...state.appliedFilters,
            filterByID: action.payload.data,
            totalRecords: sortedProductsLength,
            inputType: action.payload.inputType,
            input: action.payload.input,
            selectSortValue: action.payload.selectSortValue,
            getSortSelectID: action.payload.getSortSelectID,
            currentPage: action.payload.currentPage,
          },
          sortProducts: action.payload.sortData,
        };
      }

    case AppActions.SET_TOTAL_PAGES:
      return {
        ...state,
        appliedFilters: {
          ...state.appliedFilters,
          totalPages: action.payload,
        },
      };
    case AppActions.CHNAGE_SIDEBAR_STATUS:
      return {
        ...state,
        sidebarStatus: action.status,
      };
    case AppActions.CHNAGE_BURGER_STATUS:
      return {
        ...state,
        burgerStatus: action.status,
      };
    case AppActions.CHNAGE_CART_LOADING_STATUS:
      return {
        ...state,
        cart: {
          ...state.cart,
          loading: action.status,
        },
      };
    case AppActions.CHNAGE_ORDER_LOADING_STATUS:
      return {
        ...state,
        orders: {
          ...state.orders,
          loading: action.status,
        },
      };
    case AppActions.FILTER_CLEAR_DATA_SUCCESS:
      return {
        ...state,
        orders: {
          ...state.orders,
          loading: action.status,
        },
        appliedFilters: {
          ...state.appliedFilters,
          currentPage: 1,
        },
      };
    case AppActions.CHNAGE_ADDED_CART_STATUS:
      return {
        ...state,
        cart: {
          ...state.cart,
          itemAdded: action.status,
        },
      };
    case AppActions.SORT_BY_VALUE:
      if (action.payload === '-') {
        return {
          ...state,
          products: state.appliedFilters.filterByID,
          appliedFilters: {
            ...state.appliedFilters,
            totalRecords: action.totalRecords,
          },
        };
      } else {
        return {
          ...state,
          products: action.payload,
          sortProducts: action.payload,
          appliedFilters: {
            ...state.appliedFilters,
            totalRecords: action.totalRecords,
          },
        };
      }
    case AppActions.SET_DISCOUNT:
      return {
        ...state,
        cart: {
          ...state.cart,
          discount: {
            enabled: action.data.enabled,
            discount: action.data.discount,
            code: action.data.code,
            expires_at: action.data.expires_at,
          },
        },
      };
    case AppActions.REMOVE_DISCOUNT:
      return {
        ...state,
        cart: {
          ...state.cart,
          discount: {
            code: '',
            discount: 0,
            enabled: false,
            expires_at: null,
          },
        },
      };

    default:
      return state;
  }
};

export default rootReducer;
