//React/redux imports
import React from "react";
import { useSelector } from "react-redux";
//Redux-reducer
import { AppStore } from "../../../../@redux/reducers/reducers";
import LandPlots from "../../../../components/Landplots/LandPlots";
import useWindowSize from "../../../../utils/windowSize";
// Component import
//styles
import classes from "./LandPlot.module.scss";

export type SearchById = {
  id?: number;
  geometry?: number[];
  name?: string;
  owner?: string;
  value?: number
}
const LandPlot = ({ totalRecords }: React.ComponentState) => {
  const landPlotsByName = useSelector((state: AppStore) => state.products)
  const emptyFIlterData = useSelector((state: AppStore) => state.appliedFilters.filterByID)

  const { width } = useWindowSize();
  return (
    <div className={classes.Container}>
      <div className={classes.textWraper}>
        <div>
          {/* <p>
            <span>
              {totalRecords}
            </span> NFTs IN TOTAL
          </p> */}
          {width > 768 ? <span> {totalRecords} </span> : null}
          <p>{width < 768 ? <span>{totalRecords}</span> : null} NFTS FOUND WITH THESE FILTERS</p>
        </div>
        <p className={classes.totalNumber}>99.888 IN TOTAL</p>
      </div>
      <div className={classes.Container2Landplot}>
        <React.Fragment>
          <LandPlots pageData={"lands"} data={landPlotsByName.length === 0 ? emptyFIlterData : landPlotsByName} />
        </React.Fragment>
      </div>

    </div >
  );
};

export default LandPlot;