import classes from "./LandPlots.module.scss";
import { Link, useHistory } from "react-router-dom";
import React from "react";
import modifyPdf from "../../pages/YourWallet/ModifyPdf";
import {routes} from "../../config/Router/Router";

export interface SearchById {
    id?: number;
    geometry?: number[];
    name?: string;
    owner?: string;
    value?: number
}
type LandPlotData = {
    data: SearchById[];
    pageData: string;
}

const LandPlots = ({ data, pageData }: LandPlotData) => {
    let history = useHistory();
    return (
        <div className={classes.Container2}>
            <div className={classes.Container2Landplot}>
                <React.Fragment>
                    {data.length === 0 && (
                      <div className={classes.emptyLandsContainer}>
                          <div className={classes.emptyLands}>You do not own any lands</div>
                          <a
                            target="_blank"
                            className={classes.howToBuy}
                            rel="noreferrer noopener"
                            href='https://www.mars4.me/post/how-to-buy-mars4-nfts'
                          >
                              Learn how to buy
                          </a>
                      </div>
                    )}
                    {data.map((item) => {
                        return (
                            <div key={item.id} className={classes.Container2Plot} onClick={() => history.push({ state: { item, pageData } })}>
                                <Link style={{ textDecoration: 'none' }} to={`${routes.landPlot}/${item.id}`}>
                                    <div className={classes.lantPlotsImg}>
                                        <img src={`https://mars4-cdn.fra1.cdn.digitaloceanspaces.com/${item.id}.png`} alt={item.name} />
                                        <span className={classes.onHoverText}>Quick View</span>
                                    </div>
                                    <div>
                                        <p>{item.name}</p>
                                        <span>No.{item.id}</span>
                                    </div>
                                </Link>
                                {pageData === "myempire" ?
                                    <div>
                                        <button className={classes.YourWalletButton} onClick={() => modifyPdf(item)}>
                                            GET CERTIFICATE
                                        </button>
                                    </div>
                                    : null}
                            </div>
                        )
                    })
                    }
                </React.Fragment>
            </div>
        </div >
    );
};

export default LandPlots;