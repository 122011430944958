import React, { useState } from 'react';
import { usdtContractAbi } from '../../../data/bsc.js';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../@redux/reducers/reducers';
import classes from './OrderContainer.module.scss';
import QRcodeImg from '../../../assets/Images/Qr-Code.svg';
import CopyCodeImg from '../../../assets/Images/Copy-Address.svg';
import TrashBinImg from '../../../assets/Images/trash-bin.svg';
import { sidebarStatus } from '../../../@redux/actions';
import Modal from '../../Modal/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CalculateLandplotPrice from '../components/CalculateLandplotPrice';
import ExpireTimer from '../components/ExpireTimer';
import useCopyToClipboard from '../../../utils/useCopyToClipboard';
import useWindowSize from '../../../utils/windowSize';
import web3Modal from '../../../helpers/web3modal';
import { connect } from '../../../helpers/provider';

const { ethers } = require('ethers');

type PaymentType = 'eth' | 'mars4' | 'usdt' | 'usdc';

const OrderContainer = ({
  feature,
  index,
  pricing,
  onSingleBlockPop,
}: React.ComponentState) => {
  const dispatch = useDispatch();
  const user = useSelector((state: AppStore) => state.user.userAddress);
  const chainID = useSelector((state: AppStore) => state.user.userChainId);
  const order = useSelector((state: AppStore) => state.orders.orderList);
  const sitebarStatus = useSelector((state: AppStore) => state.sidebarStatus);

  const [value, copy] = useCopyToClipboard();
  const [selectedPaymentType, setSelectedPaymentType] =
    useState<PaymentType>('eth');
  const [modal, setModal] = useState<boolean>(false);
  const [isExpiredDate, setIsExpiredDate] = useState<boolean>(false);
  const [modalType, setModalType] = useState<any>({
    modalT: '',
    orderIndex: 0,
  });

  const landPlotPriceToPayETH =
    order[index][0].paymentAmount / 1000000000000000000;
  const mars4TotalOrder =
    order[index][0].paymentAmountMars4Tokens / 1000000000000000000;
  const lanPlotPriceToPayUSD = order[index][0].paymentAmountTokens / 1000000;

  const exactAmount =
    selectedPaymentType === 'eth'
      ? landPlotPriceToPayETH
      : selectedPaymentType === 'mars4'
      ? mars4TotalOrder
      : lanPlotPriceToPayUSD;

  const Toggle = (props: string, index?: number) => {
    setModalType({
      modalT: props,
      orderIndex: index,
    });
    setModal(!modal);
  };

  const { width } = useWindowSize();
  if (width > 980) {
    dispatch(sidebarStatus(true));
  }

  const handleConnectWallet = () => {
    connect();
  };

  const handlePurchaseTrack = () => {
    try {
      // @ts-ignore
      window.dataLayer.push({ ecommerce: null });
      feature.forEach((singleFeature: any) => {
        // @ts-ignore
        window.dataLayer.push({
          event: 'transaction',
          ecommerce: {
            purchase: {
              actionField: {
                id: singleFeature.orderId,
                revenue: (singleFeature.paymentAmountTokens / 1000000).toFixed(
                  2
                ),
              },
              products: [
                {
                  id: singleFeature.OBJECTID,
                  name: singleFeature.TOKENID,
                  price: (singleFeature.paymentAmountTokens / 1000000).toFixed(
                    2
                  ),
                  quantity: 1,
                },
              ],
            },
          },
        });
      });
    } catch (err: any) {}
  };

  const startPaymentETH = async (ether: string, addr: string) => {
    // @ts-ignore
    window.fbq('track', 'Purchase');
    // @ts-ignore
    window.esk('event', 'Pay');
    if (user !== '') {
      try {
        const instance = await web3Modal.connect();
        if (!instance) {
          toast.error('No crypto wallet found. Please install it.', {
            autoClose: 3000,
          });
        }
        if (chainID !== 1) {
          toast.error(
            'Wrong network.Change your network to `Ethereum Mainnet`',
            { autoClose: 3000 }
          );
        } else {
          const provider = new ethers.providers.Web3Provider(instance);
          const signer = provider.getSigner();
          ethers.utils.getAddress(addr);
          await signer.sendTransaction({
            to: addr,
            value: ethers.utils.parseEther(ether),
          });
          handlePurchaseTrack();
        }
      } catch (err: any) {
        if (err.code === 'INSUFFICIENT_FUNDS') {
          toast.error('Insufficient funds', {
            autoClose: 3000,
          });
        }
        if (err.code === 4001) {
          toast.error('User denied transaction signature', {
            autoClose: 3000,
          });
        }
      }
    } else {
      toast.error('Please connect to your wallet.', { autoClose: 3000 });
    }
  };
  const startPaymentMARS = async (token: string, addr: string) => {
    // @ts-ignore
    window.fbq('track', 'Purchase');
    // @ts-ignore
    window.esk('event', 'Pay');
    if (user !== '') {
      try {
        const instance = await web3Modal.connect();
        if (!instance) {
          toast.error('No crypto wallet found. Please install it.', {
            autoClose: 3000,
          });
        }
        if (user === '') {
          toast.error('Please connect.', { autoClose: 3000 });
        }
        if (chainID !== 1) {
          toast.error(
            'Wrong network.Change your network to `Ethereum Mainnet`',
            { autoClose: 3000 }
          );
        } else {
          const provider = new ethers.providers.Web3Provider(instance);
          const signer = provider.getSigner();
          ethers.utils.getAddress(addr);
          const iface = new ethers.utils.Interface(usdtContractAbi);
          const finalPriceValue = BigInt(token);
          const data = iface.encodeFunctionData('transfer', [
            addr,
            finalPriceValue,
          ]);

          await signer.sendTransaction({
            to: process.env.REACT_APP_MARS4,
            value: `0x0`,
            data,
          });
          handlePurchaseTrack();
        }
      } catch (err: any) {
        if (err.code === 'UNPREDICTABLE_GAS_LIMIT') {
          toast.error('Insufficient funds', {
            autoClose: 3000,
          });
        }
        if (err.code === 4001) {
          toast.error('User denied transaction signature', {
            autoClose: 3000,
          });
        }
      }
    } else {
      toast.error('Please connect to your wallet', { autoClose: 3000 });
    }
  };
  const startPaymentUSDT = async (token: string, addr: string) => {
    // @ts-ignore
    window.fbq('track', 'Purchase');
    // @ts-ignore
    window.esk('event', 'Pay');
    if (user !== '') {
      try {
        const instance = await web3Modal.connect();
        if (!instance) {
          toast.error('No crypto wallet found. Please install it.', {
            autoClose: 3000,
          });
        }
        if (user === '') {
          toast.error('Please connect.', { autoClose: 3000 });
        }
        if (chainID !== 1) {
          toast.error(
            'Wrong network.Change your network to `Ethereum Mainnet`',
            { autoClose: 3000 }
          );
        } else {
          const provider = new ethers.providers.Web3Provider(instance);
          const signer = provider.getSigner();
          ethers.utils.getAddress(addr);
          const iface = new ethers.utils.Interface(usdtContractAbi);
          const data = iface.encodeFunctionData('transfer', [
            addr,
            parseInt(token),
          ]);
          await signer.sendTransaction({
            to: process.env.REACT_APP_USDT,
            value: `0x0`,
            data,
          });
          handlePurchaseTrack();
        }
      } catch (err: any) {
        if (err.code === -32000) {
          toast.error('Insufficient funds', {
            autoClose: 3000,
          });
        }
        if (err.code === 4001) {
          toast.error('User denied transaction signature', {
            autoClose: 3000,
          });
        }
        if (err.code === 'UNPREDICTABLE_GAS_LIMIT') {
          toast.error('transfer amount exceeds balance', {
            autoClose: 3000,
          });
        }
      }
    } else {
      toast.error('Please connect to your wallet', { autoClose: 3000 });
    }
  };
  const startPaymentUSDC = async (token: string, addr: string) => {
    // @ts-ignore
    window.fbq('track', 'Purchase');
    // @ts-ignore
    window.esk('event', 'Pay');
    if (user !== '') {
      try {
        const instance = await web3Modal.connect();
        if (!instance) {
          toast.error('No crypto wallet found. Please install it.', {
            autoClose: 3000,
          });
        }
        if (user === '') {
          toast.error('Please connect.', { autoClose: 3000 });
        }
        if (chainID !== 1) {
          toast.error(
            'Wrong network.Change your network to `Ethereum Mainnet`',
            { autoClose: 3000 }
          );
        } else {
          const provider = new ethers.providers.Web3Provider(instance);
          const signer = provider.getSigner();
          ethers.utils.getAddress(addr);
          const iface = new ethers.utils.Interface(usdtContractAbi);
          const data = iface.encodeFunctionData('transfer', [
            addr,
            parseInt(token),
          ]);
          await signer.sendTransaction({
            to: process.env.REACT_APP_USDC,
            value: `0x0`,
            data,
          });
          handlePurchaseTrack();
        }
      } catch (err: any) {
        if (err.code === 'INSUFFICIENT_FUNDS') {
          toast.error('Insufficient funds', {
            autoClose: 3000,
          });
        }
        if (err.code === 4001) {
          toast.error('User denied transaction signature', {
            autoClose: 3000,
          });
        }
        if (err.code === 'UNPREDICTABLE_GAS_LIMIT') {
          toast.error('transfer amount exceeds balance', {
            autoClose: 3000,
          });
        }
      }
    } else {
      toast.error('Please connect to your wallet', { autoClose: 3000 });
    }
  };

  const handlePayClick = () => {
    switch (selectedPaymentType) {
      case 'eth':
        startPaymentETH(
          `${order[index][0].paymentAmount / 1000000000000000000}`,
          `${order[index][0].paymentAddress}`
        );
        break;
      case 'mars4':
        startPaymentMARS(
          `${order[index][0].paymentAmountMars4Tokens}`,
          `${order[index][0].paymentAddress}`
        );
        break;
      case 'usdc':
        startPaymentUSDC(
          `${order[index][0].paymentAmountTokens}`,
          `${order[index][0].paymentAddress}`
        );
        break;
      case 'usdt':
        startPaymentUSDT(
          `${order[index][0].paymentAmountTokens}`,
          `${order[index][0].paymentAddress}`
        );
        break;
    }
  };

  return (
    <span key={index + 2}>
      <div key={index} className={classes.SideBarRecentOrderLandInfo}>
        <h6>Order No.{index + 1}</h6>
        <div className={classes.SideBarUtilsContainer}>
          <div className={classes.SideBarExpireTime}>
            <p className={classes.waitingText}>Waiting for payment</p>
            <ExpireTimer
              list={index}
              expireDate={feature[0]}
              setIsExpiredDate={setIsExpiredDate}
            />
          </div>
          <div
            className={classes.trashBin}
            onClick={() => Toggle('remove', index)}
          >
            <span className={classes.ToolTip}>Delete your order</span>
            <img
              className={classes.trashBinHover}
              src={TrashBinImg}
              alt='Mars4.me remove from orders logo'
            />
          </div>
        </div>
      </div>
      <div className={classes.SideBarCartOrderBlocks}>
        <div className={classes.SideBarLandPlotsWrapper}>
          <h4 className={classes.H4fontStyleCourier}>Landplots</h4>
          <div className={classes.cartLandPlots}>
            {feature.map((item: any) => {
              const lastElement = feature.slice(-1);
              const slotCount =
                !!item.SLOT_COUNT && item.SLOT_COUNT < 6 ? (
                  <span>{`(${item.SLOT_COUNT})`}</span>
                ) : undefined;
              return (
                <span
                  key={item.OBJECTID}
                  data-plot-id={item.TOKENID}
                  onClick={(e) => onSingleBlockPop(e.currentTarget)}
                  className={classes.SideBarRecentOrderBox}
                >
                  {`#${item.TOKENID}`}
                  {slotCount}
                  {lastElement[0]?.TOKENID === item.TOKENID ? '' : ','}
                </span>
              );
            })}
          </div>
        </div>
      </div>
      {sitebarStatus ? (
        <Modal
          paymentAddress={order[index][0].paymentAddress}
          show={modal}
          data={modalType}
          close={Toggle}
        />
      ) : null}
      <div className={classes.paymentSelectContainer}>
        <div className={classes.paymentSelectLabel}>Payment option</div>
        <select
          className={classes.paymentSelect}
          onChange={(event) =>
            setSelectedPaymentType(event.target.value as PaymentType)
          }
          value={selectedPaymentType}
        >
          <option value='eth'>ETH</option>
          <option value='mars4'>MARS4</option>
          <option value='usdt'>USDT</option>
          <option value='usdc'>USDC</option>
        </select>
      </div>
      <CalculateLandplotPrice
        selectedPaymentType={selectedPaymentType}
        isExpiredDate={isExpiredDate}
        pricing={pricing}
        index={index}
      />
      <div className={classes.SideBarRecentOrderButtonContainer}>
        {user ? (
          <React.Fragment>
            <button
              onClick={() => handlePayClick()}
              className={classes.SideBarRecentOrderButtonRow}
            >
              PAY
            </button>
          </React.Fragment>
        ) : (
          <>
            <button
              onClick={handleConnectWallet}
              className={classes.SideBarRecentOrderButtonRow}
            >
              CONNECT WALLET
            </button>
          </>
        )}
      </div>
      <div className={classes.SideBarCustomDashedLine} />

      <div className={classes.manualPayText}>OR pay manually:</div>
      <div className={classes.SideBarRecentOrderAddress}>
        <div className={classes.PaymentInfoContainer}>
          <h4 className={classes.H4fontStyleCourier}>Payment address</h4>
          <p id='walletAddress' className={classes.walletAddress}>
            {order[index][0].paymentAddress}
          </p>
        </div>
        <div className={classes.addressCopyMethods}>
          <div className={classes.QRCode} onClick={() => Toggle('scanQR')}>
            <span className={classes.ToolTip}>Scan QR Code</span>
            <img
              className={classes.QRICON}
              src={QRcodeImg}
              alt='Mars4.me QR code'
            />
          </div>
          <div
            className={classes.CopyCode}
            onClick={() => copy(order[index][0].paymentAddress)}
          >
            <span className={classes.ToolTip}>
              {value !== order[index][0].paymentAddress
                ? 'Copy address'
                : 'Copied'}
            </span>
            <img
              src={CopyCodeImg}
              className={classes.COPYICON}
              alt='Mars4.me copy wallet address'
            />
          </div>
        </div>
      </div>
      <div className={classes.exactAmountContainer}>
        <h4 className={classes.H4fontStyleCourier}>Pay (exact) amount:</h4>
        <div className={classes.valueContainer}>
          <p className={classes.exactAmount}>
            {exactAmount} {selectedPaymentType}
          </p>
          <div
            className={classes.CopyAmount}
            onClick={() => copy(exactAmount.toString())}
          >
            <span className={classes.ToolTip}>
              {value !== exactAmount.toString() ? 'Copy amount' : 'Copied'}
            </span>
            <img
              src={CopyCodeImg}
              className={classes.COPYICON}
              alt='Mars4.me copy exact amount'
            />
          </div>
        </div>
      </div>
      <span className={classes.manualPayment}>
        <label> DO NOT </label> pay from an exchange wallet!
      </span>
    </span>
  );
};
export default OrderContainer;
