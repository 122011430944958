import { Link, useHistory } from 'react-router-dom'
import React, { useCallback, useEffect, useState } from "react";
//components
import Footer from '../../components/Footer/Footer';
import sideArrow from "../../assets/Images/side-arrow.svg"
import SideBar from '../../components/SideBar/SideBar';
//style
import classes from "./SingleLandPlot.module.scss";
// extra
import useWindowSize from '../../utils/windowSize';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../@redux/reducers/reducers';
import axios, { AxiosResponse } from 'axios';
import { sidebarStatus } from '../../@redux/actions';
import { SERVER_URL } from '../..';
import {URL_PREFIX} from "../../config/Router/Router";

const SingleLandPlot = (props: any) => {
    const [data, setData] = useState(props);
    const [pageData, setPageData] = useState('')
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        if (props.location.state === undefined) {
            const id = props.match.params.id
            axios
                .get(`https://app.mars4.me/_tokens?filter[id]=${id}`)
                .then((plots: AxiosResponse) => {
                    setData(plots.data[0]);
                    setPageData("lands")
                })
                .catch(error => console.log(error));
        } else {
            setData({ ...props.history.location.state.item })
            setPageData(props.history.location.state.pageData)
        }
    }, [])
    const dispatch = useDispatch();
    let history = useHistory();
    const [pricing, setPricing] = useState<React.ComponentState>("");
    const sitebarStatus = useSelector((state: AppStore) => state.sidebarStatus);
    const updatePricing = useCallback(() => {
        axios.get(`${SERVER_URL}/pricing`).then((data: AxiosResponse) => setPricing(data.data))
          .catch(error => console.log(error));
    }, []);

    useEffect(() => {
        setInterval(updatePricing, 150000)
        updatePricing()

    }, [updatePricing])
    dispatch(sidebarStatus(false))
    const handleClick = () => {
        history.push(`${URL_PREFIX}/#${data.id}`)
    }
    const { width } = useWindowSize();
    return (
        <div className={classes.container}>
            <div className={classes.wrapper}>
                {sitebarStatus ? <SideBar pricing={pricing} /> : null}
                <div className={classes.landPlotData}>
                    <div className={classes.landPlotName}>
                        <Link className={width > 840 ? classes.backToHomeButton : classes.backToHomeCross} style={{ textDecoration: 'none' }} to={`${URL_PREFIX}/${pageData}`}>
                            <img className={width > 840 ? classes.backHome : "d-none"} src={sideArrow} alt='GO BACK TO MARS4 Page' />
                            {width > 840 ? `BACK TO ${pageData.toUpperCase()}` : null}
                        </Link>
                        <h1 className={classes.plotName}>{data.name}</h1>
                        <span className={classes.plotNumber}>No.{data.id}</span>
                        <div className={classes.ownerContainer}>
                            <h4>OWNER</h4>
                            <div className={classes.owner}>{data.owner === "0x0" ? `${data.name} doesn't have an owner.` : `This landplot belongs to ${data.owner}`}</div>
                        </div>
                        <div>
                            <button onClick={() => handleClick()} className={classes.viewOnMapBTN}>VIEW ON MAP</button>
                            {/* <button onClick={()=> dispatch(addToCart(data))} className={classes.viewOnMapBTN}>BUY</button> */}
                        </div>
                    </div>
                    <div className={classes.imageContainer}>
                        <img className={classes.landPlotImage} src={`https://mars4-cdn.fra1.cdn.digitaloceanspaces.com/${data.id}.png`} alt={data.name} />
                    </div>
                </div>
                <div className={classes.SideBarCustomDashedLine} />
                {data.description && (
                  <div className={classes.contentSectionContainer}>
                      <div className={classes.content}>
                          <h4>DESCRIPTION</h4>
                          <p>{data.description}</p>
                      </div>
                  </div>
                )}
                <div className={classes.contentSectionContainer}>
                    <div className={classes.content}>
                        <h4>COORDINATES</h4>
                        <textarea readOnly value={JSON.stringify(data.geometry?.rings)} />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};
export default SingleLandPlot;

