import React from "react";
import QRGenerator from "react-qr-code";
import classes from "./Modal.module.scss"
import sideArrow from "../../assets/Images/side-arrow.svg"
import { useDispatch } from "react-redux";
import { removeAllOrderItems } from "../../@redux/actions";


const Modal = ({ show, close, paymentAddress, data }: any) => {
    const dispatch = useDispatch();
    const removeOrder = () => {
        close();
        dispatch(removeAllOrderItems(data.orderIndex))
    };
    return (
        <React.Fragment>
            {
                show ?
                    <div
                        onClick={()=>close()}

                        className={classes.modalContainer}
                    >
                        {data.modalT === "scanQR" ?
                            <>
                                <div className={classes.modalCode}>{paymentAddress}</div>
                                <div className={classes.modals} >
                                    <QRGenerator value={paymentAddress} />

                                </div>
                                <div className={classes.backHomeContainer}>
                                    <img className={classes.backHome} src={sideArrow} alt="Mars4 SideArrow" />
                                    <div onClick={() => close()} className={classes.modalCode}>BACK</div>
                                </div>
                            </>
                            :
                            <>
                                <div className={classes.modalCode}>Your reservation will be cancelled. Do you want to continue?</div>
                                <div className={classes.backHomeContainer}>
                                    <button onClick={() => removeOrder()} className={classes.modalCode}>DELETE</button>
                                    <button onClick={() => close()} className={classes.modalCode}>CANCEL</button>
                                </div>
                            </>
                        }

                    </div>
                    : null
            }
        </React.Fragment>
    );
};

export default Modal;