import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./@redux/store";
import './assets/sass/App.scss';
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, RouteComponentProps } from "react-router-dom";
import Router from "./config/Router/Router";
import ReactGA, { FieldsObject } from 'react-ga';
import { hotjar } from 'react-hotjar';
import {ToastContainer} from "react-toastify";
// export const SERVER_URL = "http://167.99.37.47:8080";
// export const SERVER_URL = "http://localhost:8080"
export const SERVER_URL = "https://app.mars4.me"

export default <P extends RouteComponentProps>(
  WrappedComponent: React.ComponentType<P>,
  options: FieldsObject = {},
) => {
  const trackPage = (page: string) => {
    ReactGA.set({ page, ...options });
    ReactGA.pageview(page);
  };

  return (props: P) => {
    useEffect(() => {
      trackPage(props.location.pathname);
    }, [props.location.pathname]);

    return <WrappedComponent {...props} />;
  };
}
ReactGA.pageview(window.location.pathname + window.location.search);

export const SampleComponent = () => {
  useEffect(() => {
    hotjar.initialize(2776327, 6);
  }, [])
}
ReactDOM.render(
  <React.StrictMode>
      <meta
        httpEquiv="Content-Type"
        content="text/html; charset=utf-8"
      ></meta>
      <meta
        name="viewport"
        content="initial-scale=1, width=device-width, maximum-scale=1, user-scalable=0"
      />
    <Provider store={store}>
      <BrowserRouter>
        <Router/>
      </BrowserRouter>
      <ToastContainer/>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
