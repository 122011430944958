import axios from "axios";
import { Dispatch } from "redux";
export enum FilterActions {
  FILTER_BY_VALUE_START = "FILTER_BY_VALUE_START",
  FILTER_BY_VALUE_SUCCESS = "FILTER_BY_VALUE_SUCCESS",
  FILTER_BY_VALUE_FAIL = "FILTER_BY_VALUE_FAIL",
  FILTER_BY_NAME_SUCCESS = "FILTER_BY_NAME_SUCCESS",
  FILTER_BY_NAME_FAIL = "FILTER_BY_NAME_FAIL",
  FILTER_CLEAR_DATA_SUCCESS = "FILTER_CLEAR_DATA_SUCCESS",
  FILTER_CLEAR_DATA_FAIL = "FILTER_CLEAR_DATA_FAIL",
  SORT_BY_VALUE = "SORT_BY_VALUE",
  SORT_BY_VALUE_FAIL = "SORT_BY_VALUE_FAIL",
  SET_TOTAL_PAGES = "SET_TOTAL_PAGES",
  SET_TOTAL_PAGES_FAIL = "SET_TOTAL_PAGES_FAIL",

}


export const clearFilterInput =
  () =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch({
          type: FilterActions.FILTER_CLEAR_DATA_SUCCESS,
        });
      } catch (error) {
        dispatch({
          type: FilterActions.FILTER_CLEAR_DATA_FAIL,
          payload: error,
        });
      }
    };
export const setTotalPage =
  (totalPages:number) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch({
          type: FilterActions.SET_TOTAL_PAGES,
          payload:totalPages
        });
      } catch (error) {
        dispatch({
          type: FilterActions.SET_TOTAL_PAGES_FAIL,
          payload: error,
        });
      }
    };
export const filterLandplotByInputType =
  (input: string | number, currentPage: number, inputType: string, selectSortValue: string, getSortSelectID: number) =>
    async (dispatch: Dispatch) => {
      dispatch({ type: FilterActions.FILTER_BY_VALUE_START });
      try {
        let totalRecords;
        switch (selectSortValue) {
          case "epic":
            totalRecords = 8;
            break;
          case "super-rare":
            totalRecords = 34;
            break;
          case "rare":
            totalRecords = 58;
            break;
          case "unique":
            totalRecords = 789;
            break;
          case "common":
            totalRecords = 99000;
            break;
          case "":
            totalRecords = 99888;
            break;
          case undefined:
            totalRecords = 99888;
            break;
          default:
            break;
        }
        const page = currentPage! - 1;
        const data = await axios.get(
          `https://app.mars4.me/_tokens?filter[rarity]=${selectSortValue}&filter[${inputType}]=${input}&page=${page},20`
        );
        const sortData = await axios.get(
          `https://app.mars4.me/_tokens?filter[rarity]=${selectSortValue}&filter[${inputType}]=${input}&page=0,99`
        );
        dispatch({
          type: FilterActions.FILTER_BY_VALUE_SUCCESS,
          payload: {
            data: data.data,
            sortData: sortData.data,
            totalRecords,
            selectSortValue,
            inputType,
            input,
            getSortSelectID,
            currentPage
          }
        });
      } catch (error) {
        dispatch({
          type: FilterActions.FILTER_BY_VALUE_FAIL,
          payload: error,
        });
      }
    };
export const sortByValue =
  (value: string, currentPage: any) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        let totalRecords;
        switch (value) {
          case "epic":
            totalRecords = 8;
            break;
          case "super-rare":
            totalRecords = 34;
            break;
          case "rare":
            totalRecords = 58;
            break;
          case "unique":
            totalRecords = 788;
            break;
          case "common":
            totalRecords = 99000;
            break;
          case "":
            totalRecords = 99888;
            break;
          default:
            break;
        }
        const page = currentPage - 1;
        const data = await axios.get(
          `https://app.mars4.me/_tokens?filter[rarity]=${value}&page=${page},20`
        );
        dispatch({
          type: FilterActions.SORT_BY_VALUE,
          payload: data.data,
          totalRecords
        });
      } catch (error) {
        dispatch({
          type: FilterActions.SORT_BY_VALUE_FAIL,
          payload: error,
        });
      }
    };


    // "https://app.mars4.me/_tokens?page=0,20"