import React from "react";
import MarsSceneView from "../../components/esri/MarsSceneView";
import web3Modal from "../../helpers/web3modal";
import { connect } from "../../helpers/provider";
import { useEffect } from "react";

function LandingPage() {
  useEffect(() => {
    if (web3Modal?.cachedProvider) {
      connect();
    }
  }, []);
  return (
    <React.Fragment>
        <MarsSceneView />
    </React.Fragment>
  );
}

export default LandingPage;
