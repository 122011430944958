import { useLocation } from "react-router";
import classes from "./ErrorPage.module.scss"
 const NoMatch = () => {
    let location = useLocation();
  
    return (
      <div className={classes.Container}> 
        <h3>
          Page Not Found 404 <code>{location.pathname}</code>
        </h3>
      </div>
    );
  }
  export default NoMatch;
