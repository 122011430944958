import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import download from "downloadjs"
import certificate from './certificate.pdf'
import { SearchById } from '../Gallery/components/LandPlot/LandPlot';
import moment from 'moment';

async function modifyPdf(item:SearchById) {
  const existingPdfBytes = await fetch(certificate).then(res => res.arrayBuffer())
  const pdfDoc = await PDFDocument.load(existingPdfBytes)
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
  const courierFont = await pdfDoc.embedFont(StandardFonts.Courier);
  const pages = pdfDoc.getPages();
  const date =   moment().format('llll');

  const firstPage = pages[0]
  const { height } = firstPage.getSize();
  firstPage.drawText(`NFT ${item.name}`, {
    x: 320,
    y: height - 510,
    size: 40,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  })
  firstPage.drawText(`${item.name}`, {
    x: 295,
    y: height - 634,
    size: 16,
    font: helveticaFont,
    color: rgb(0.40, 0.40, 0.40),
  })
  firstPage.drawText(`${item.owner}`, {
    x: 190,
    y: height - 810,
    size: 20,
    font: courierFont,
    color: rgb(0, 0, 0),
  })
  firstPage.drawText(`${date}`, {
    x: 280,
    y: height - 890,
    size: 20,
    font: courierFont,
    color: rgb(0, 0, 0),
  })
  const pdfBytes = await pdfDoc.save()
  download(pdfBytes, `landplot ${item.name} certificate`, "application/pdf")

}
export default modifyPdf;