import { createStore, Store, applyMiddleware } from "redux";
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer, { AppStore } from "./reducers/reducers";
import { composeWithDevTools } from 'redux-devtools-extension';


const persistConfig = {
  key: "mars-store",
  storage,
  whitelist: ["volume", "cart", "orders", "reserved"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
let store: Store<AppStore> = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)
));

persistStore(store);

export default store;
